import React, { useState, useEffect, useRef }  from 'react'
import '../styles/monster.css'
import monster_img from '../icons/monster-img.png'
import accelerate_img from '../icons/accelerate.png'
import profile_img from '../icons/profile.png'
import fruit_img from '../icons/fruit.png'
// import aries_img1 from '../icons/constellation/aries1.png'
import aries_img2 from '../icons/constellation/aries2.png'
// import taurus_img1 from '../icons/constellation/taurus1.png'
import taurus_img2 from '../icons/constellation/taurus2.png'
// import gemini_img1 from '../icons/constellation/gemini1.png'
import gemini_img2 from '../icons/constellation/gemini2.png'
// import cancer_img1 from '../icons/constellation/cancer1.png'
import cancer_img2 from '../icons/constellation/cancer2.png'
// import leo_img1 from '../icons/constellation/leo1.png'
import leo_img2 from '../icons/constellation/leo2.png'
// import virgo_img1 from '../icons/constellation/virgo1.png'
import virgo_img2 from '../icons/constellation/virgo2.png'
// import libra_img1 from '../icons/constellation/libra1.png'
import libra_img2 from '../icons/constellation/libra2.png'
// import scorpio_img1 from '../icons/constellation/scorpio1.png'
import scorpio_img2 from '../icons/constellation/scorpio2.png'
// import sagittarius_img1 from '../icons/constellation/sagittarius1.png'
import sagittarius_img2 from '../icons/constellation/sagittarius2.png'
// import capricornus_img1 from '../icons/constellation/capricornus1.png'
import capricornus_img2 from '../icons/constellation/capricornus2.png'
// import aquarius_img1 from '../icons/constellation/aquarius1.png'
import aquarius_img2 from '../icons/constellation/aquarius2.png'
// import pisces_img1 from '../icons/constellation/pisces1.png'
import pisces_img2 from '../icons/constellation/pisces2.png'
// import zodiac_img1 from '../icons/constellation/zodiac1.png'
import zodiac_img2 from '../icons/constellation/zodiac2.png'
// import universal_img1 from '../icons/constellation/universal1.png'
import universal_img2 from '../icons/constellation/universal2.png'
import left_img from '../icons/left.png'
import right_img from '../icons/right.png'
import Title from './title'
import AlertsFeed from './alertsfeed'
import AlertsAccelerate from './alertsaccelerate'
import AlertsProfile from './alertsprofile'
import AlertsConstellation from './alertsConstellation'
import { TAI64 } from 'tai64'
import loading from '../icons/loading.gif'



const Monster = ({resetInfo, info, address, setAddress, myContract, setMyContract, setMyWallet, myname, setMyName, tokenBalance}) => {
    const [cardIndex, setCardIndex] = useState(2);
    const [cardName1, setCardName1] = useState('Aries');
    const [cardName2, setCardName2] = useState('Taurus');
    const [cardName3, setCardName3] = useState('Gemini');
    const [cardImg1, setCardImg1] = useState(aries_img2);
    const [cardImg2, setCardImg2] = useState(taurus_img2);
    const [cardImg3, setCardImg3] = useState(gemini_img2);
    const [cardNum1, setCardNum1] = useState(0);
    const [cardNum2, setCardNum2] = useState(0);
    const [cardNum3, setCardNum3] = useState(0);
    const [claimStatus, setClaimStatus] = useState(0);
    const [doclaimcardStatus, setdoclaimcardStatus] = useState(0);
    const [rebirthStatus, setRebirthStatus] = useState(0);
    const [geneStatus, setGeneStatus] = useState(0);
    const [dorebirthStatus, setdorebirthStatus] = useState(0);
    const [docombineStatus, setdocombineStatus] = useState(0);
    const [claimName, setClaimName] = useState('0h:0m:0s');
    const [epochName, setEpochName] = useState('0d:0h:0m:0s');
    const [todName, setTodName] = useState('0d:0h:0m:0s');
    const [geneName, setGeneName] = useState('0d:0h:0m:0s');
    const [epoch, setEpoch] = useState(1);
    const [alertfeetStatus, setalertfeetStatus] = useState(0);
    const [alertprofileStatus, setalertprofileStatus] = useState(0);
    const [alertaccelerateStatus, setalertaccelerateStatus] = useState(0);
    const [mintButtonStatus, setMintButtonStatus] = useState(0);
    const [mintMonsterStatus, setMintMonsterStatus] = useState(0);
    const [alertConstellaStatus, setalertConstellaStatus] = useState(0);


    let timer=useRef();     

    function goLeft() {
        if(cardIndex > 4){
            dealIndex(cardIndex - 3);
            setCardIndex(cardIndex - 3);
        }else if(cardIndex == 4){
            dealIndex(cardIndex - 2);
            setCardIndex(cardIndex - 2);
        }else if(cardIndex == 3){
            dealIndex(cardIndex - 1);
            setCardIndex(cardIndex - 1);
        }
    }

    function goRight(){
        if(cardIndex < 11){
            dealIndex(cardIndex + 3);
            setCardIndex(cardIndex + 3);
        }else if(cardIndex == 11){
            dealIndex(cardIndex + 2);
            setCardIndex(cardIndex + 2);
        }else if(cardIndex == 12){
            dealIndex(cardIndex + 1);
            setCardIndex(cardIndex + 1);
        }
    }

    function doSetCard(num1, num2, num3){
        
            if(num1>0){
                setCardNum1(num1);
                // setCardImg1(img1);
            }
            if(num2>0){
                setCardNum2(num2);
                // setCardImg2(img2);
            }
            if(num3>0){
                setCardNum3(num3);
                // setCardImg3(img3);
            }
    }

    function dealIndex(_index){
        if(_index == 2){
            setCardImg1(aries_img2);
            setCardImg2(taurus_img2);
            setCardImg3(gemini_img2);
            setCardName1('Aries');
            setCardName2('Taurus');
            setCardName3('Gemini');
            setCardNum1(0);
            setCardNum2(0);
            setCardNum3(0);
            if(address.length > 0){
                if(info.length>0){  
                    if(info[2]){
                        doSetCard(info[2].aries, info[2].taurus, info[2].gemini);
                    }
                }
            }
        }else if(_index == 3){
            setCardName1('Taurus');
            setCardName2('Gemini');
            setCardName3('Cancer');
            setCardImg1(taurus_img2);
            setCardImg2(gemini_img2);
            setCardImg3(cancer_img2);
            setCardNum1(0);
            setCardNum2(0);
            setCardNum3(0);
            if(address.length > 0){
                if(info.length>0){  
                    if(info[2]){
                        doSetCard(info[2].taurus, info[2].gemini, info[2].cancer);
                    }
                }
            }
        }else if(_index == 4){
            setCardName1('Gemini');
            setCardName2('Cancer');
            setCardName3('Leo');
            setCardImg1(gemini_img2);
            setCardImg2(cancer_img2);
            setCardImg3(leo_img2);
            setCardNum1(0);
            setCardNum2(0);
            setCardNum3(0);
            if(address.length > 0){
                if(info.length>0){  
                    if(info[2]){
                        doSetCard(info[2].gemini, info[2].cancer, info[2].leo);
                    }
                }
            }
        }else if(_index == 5){
            setCardName1('Cancer');
            setCardName2('Leo');
            setCardName3('Virgo');
            setCardImg1(cancer_img2);
            setCardImg2(leo_img2);
            setCardImg3(virgo_img2);
            setCardNum1(0);
            setCardNum2(0);
            setCardNum3(0);
            if(address.length > 0){
                if(info.length>0){  
                    if(info[2]){
                        doSetCard(info[2].cancer, info[2].leo, info[2].virgo);
                    }
                }
            }
        }else if(_index == 6){
            setCardName1('Leo');
            setCardName2('Virgo');
            setCardName3('Libra');
            setCardImg1(leo_img2);
            setCardImg2(virgo_img2);
            setCardImg3(libra_img2);
            setCardNum1(0);
            setCardNum2(0);
            setCardNum3(0);
            if(address.length > 0){
                if(info.length>0){  
                    if(info[2]){
                        doSetCard(info[2].leo, info[2].virgo, info[2].libra);
                    }
                }
            }
        }else if(_index == 7){
            setCardName1('Virgo');
            setCardName2('Libra');
            setCardName3('Scorpio');
            setCardImg1(virgo_img2);
            setCardImg2(libra_img2);
            setCardImg3(scorpio_img2);
            setCardNum1(0);
            setCardNum2(0);
            setCardNum3(0);
            if(address.length > 0){
                if(info.length>0){  
                    if(info[2]){
                        doSetCard(info[2].virgo, info[2].libra, info[2].scorpio);
                    }
                }
            }
        }else if(_index == 8){
            setCardName1('Libra');
            setCardName2('Scorpio');
            setCardName3('Sagittarius');
            setCardImg1(libra_img2);
            setCardImg2(scorpio_img2);
            setCardImg3(sagittarius_img2);
            setCardNum1(0);
            setCardNum2(0);
            setCardNum3(0);
            if(address.length > 0){
                if(info.length>0){  
                    if(info[2]){
                        doSetCard(info[2].libra, info[2].scorpio, info[2].sagittarius);
                    }
                }
            }
        }else if(_index == 9){
            setCardName1('Scorpio');
            setCardName2('Sagittarius');
            setCardName3('Capricornus');
            setCardImg1(scorpio_img2);
            setCardImg2(sagittarius_img2);
            setCardImg3(capricornus_img2);
            setCardNum1(0);
            setCardNum2(0);
            setCardNum3(0);
            if(address.length > 0){
                if(info.length>0){  
                    if(info[2]){
                        doSetCard(info[2].scorpio, info[2].sagittarius, info[2].capricornus);
                    }
                }
            }
        }else if(_index == 10){
            setCardName1('Sagittarius');
            setCardName2('Capricornus');
            setCardName3('Aquarius');
            setCardImg1(sagittarius_img2);
            setCardImg2(capricornus_img2);
            setCardImg3(aquarius_img2);
            setCardNum1(0);
            setCardNum2(0);
            setCardNum3(0);
            if(address.length > 0){
                if(info.length>0){  
                    if(info[2]){
                        doSetCard(info[2].sagittarius, info[2].capricornus, info[2].aquarius);
                    }
                }
            }
        }else if(_index == 11){
            setCardName1('Capricornus');
            setCardName2('Aquarius');
            setCardName3('Pisces');
            setCardImg1(capricornus_img2);
            setCardImg2(aquarius_img2);
            setCardImg3(pisces_img2);
            setCardNum1(0);
            setCardNum2(0);
            setCardNum3(0);
            if(address.length > 0){
                if(info.length>0){  
                    if(info[2]){
                        doSetCard(info[2].capricornus, info[2].aquarius, info[2].pisces);
                    }
                }
            }
        }else if(_index == 12){
            setCardName1('Aquarius');
            setCardName2('Pisces');
            setCardName3('Zodiac');
            setCardImg1(aquarius_img2);
            setCardImg2(pisces_img2);
            setCardImg3(zodiac_img2);
            setCardNum1(0);
            setCardNum2(0);
            setCardNum3(0);
            if(address.length > 0){
                if(info.length>0){  
                    if(info[2]){
                        doSetCard(info[2].aquarius, info[2].pisces, info[2].zodiac);
                    }
                }
            }
        }else if(_index == 13){
            setCardName1('Pisces');
            setCardName2('Zodiac');
            setCardName3('Universal');
            setCardImg1(pisces_img2);
            setCardImg2(zodiac_img2);
            setCardImg3(universal_img2);
            setCardNum1(0);
            setCardNum2(0);
            setCardNum3(0);
            if(address.length > 0){
                if(info.length>0){  
                    if(info[2]){
                        doSetCard(info[2].pisces, info[2].zodiac, info[2].universal);
                    }
                }
            }
        }
    }
     
    const countDown = (expiretime, types) => {
        const nowTime = +new Date(); //获取當前时间的时间戳（单位毫秒）
        const times = parseInt(`${(expiretime - nowTime) / 1000}`); //把剩余时间毫秒数转化为秒
        if(times > 0){
            let d = parseInt(`${(times / 60 / 60 ) / 24}`); //计算天数 转化为整数
            let h = parseInt(`${(times / 60 / 60) % 24}`); //计算小时数 转化为整数
            let m = parseInt(`${(times / 60) % 60}`); //计算分钟数 转化为整数
            let s = parseInt(`${times % 60}`); //计算描述 转化为整数
        
            // console.log('d:', d, 'h:', h, 'm:', m, 's:', s) 
            if(d < 10){
            d = `0${d}`
            }
            if(h < 10){
            h = `0${h}`
            }
            if(m < 10){
            m = `0${m}`
            }
            if(s < 10){
            s = `0${s}`
            }
            let times_ = d + "d : " + h + "h : " + m + "m : " + s + "s";
        
        
            if(types === 1){
                // console.log(times_, types)
                setClaimName(times_)
            }else if(types ===2){
                // console.log(times_, types)
                setEpochName(times_)
            }else if(types ===3){
                // console.log(times_, types)
                setTodName(times_)
            }else if(types ===4){
                // console.log(times_, types)
                setGeneName(times_)
            }
            if(times < 3 && types === 1){
                console.log('times:', times);
                setTimeout(() => {
                    setClaimStatus(1);    
                }, 1000);
            }
            if(times < 3 && types ===3){
                console.log('times:', times);
                setTimeout(() => {
                    setRebirthStatus(1);
                }, 1000);
            }
            if(times < 3 && types ===4){
                console.log('times:', times);
                setTimeout(() => {
                    setGeneStatus(1);
                }, 1000);
            }
        }else{
            
            if(types === 1){
                
                if(times > -3){
                    console.log(claimStatus);
                    setTimeout(() => {
                        setClaimStatus(1)    
                    }, 1000);
                }
                
            }else if(types === 3){
                
                if(times > -3){
                    console.log(rebirthStatus)
                    setTimeout(() => {
                        setRebirthStatus(1)  
                    }, 1000);
                }
                
            }else if(types === 4){
                
                if(times > -3){
                    console.log(geneStatus)
                    setTimeout(() => {
                        setGeneStatus(1)  
                    }, 1000);
                }
                
            }
        }
        
    }

    async function claimCard(){
        if(address.length > 0){
            try{
                // console.log('here1')
                const contractCallGasLimit = 500_000;
                setdoclaimcardStatus(1);
                let result = await myContract.functions.claim_constellation().callParams(
                    {
                        gasLimit: contractCallGasLimit,
                    }
                ).call();
                // console.log(result.transactionResult);
                let res = await result.waitForResult();
                // console.log(res);
                // console.log(result.transactionResult);
                if(res.transactionResult.status === 'success'){
                    resetInfo();
                    setdoclaimcardStatus(0);
                    // setClaimStatus(0);
                    //info里面的时间可能没有更新
                    // window.location.reload();
                }else{
                    alert('Transaction Failed');
                    setdoclaimcardStatus(0);
    
                }
            }catch(e){
                alert(e.toString());
                setdoclaimcardStatus(0);
            }
    
          }else{
            alert('Please Connect Wallet First');
          }
    }
    async function doCombine(){
        if(address.length > 0){
            if(info[2]){
                if(info[2].aries.toString() > 0 && info[2].taurus.toString()>0 && info[2].gemini.toString()>0 && info[2].cancer.toString()>0 && info[2].leo.toString()>0 && info[2].virgo.toString()>0 && info[2].libra.toString()>0 && info[2].scorpio.toString()>0 && info[2].sagittarius.toString()>0 && info[2].capricornus.toString()>0 && info[2].aquarius.toString()>0 && info[2].pisces.toString()>0){
                    try{
                        // console.log('here1')
                        setdocombineStatus(1);
                        const contractCallGasLimit = 600_000;
                        const _price = (500)*((10**9));
                        let assetId = '0x6b652ac9cf1f89fd76e4e8438611f7500fa6b8523bdd0609f0f045694bf0b321'; //token assetid
                        let result = await myContract.functions.combine_constellation().callParams(
                            {
                                forward: [_price, assetId],
                                gasLimit: contractCallGasLimit,
                            }
                        ).call();
                        // console.log(result.transactionResult);
                        let res = await result.waitForResult();
                        // console.log(res);
                        // console.log(result.transactionResult);
                        if(res.transactionResult.status === 'success'){
                            resetInfo();
                            // setdocombineStatus(0);
                        }else{
                            // setdoclaimcardStatus(0);
                            alert('Transaction Failed');
            
                        }
                        setdocombineStatus(0);
                    }catch(e){
                        // console.log(e);
                        // setdoclaimcardStatus(0);
                        alert(e.toString());
                        setdocombineStatus(0);
                    }
                    
                }else{
                    alert('You Should Have Enough Constellation Cards');

                }
            }else{
                alert('You Have 0 Constellation Card');
            }
    
          }else{
            alert('Please Connect Wallet First');
          }

    }

    async function reBirth(){
        if(address.length > 0){
            try{
                // console.log('here1')
                setdorebirthStatus(1);
                const contractCallGasLimit = 500_000;
                const _price = (0.0003)*((10**9));
                let baseAssetId = '0xf8f8b6283d7fa5b672b530cbb84fcccb4ff8dc40f8176ef4544ddb1f1952ad07';
                let result = await myContract.functions.rebirth().callParams({
                  forward: [_price, baseAssetId],
                  gasLimit: contractCallGasLimit,
                }).call();
                let res = await result.waitForResult();
                // console.log(res);
                // console.log(result.transactionResult);
                if(res.transactionResult.status === 'success'){
                    setdorebirthStatus(0);
                    resetInfo();
                    // navigate('/');
                    // window.location.reload();
                }else{
                    alert('Transaction Failed');
                    setdorebirthStatus(0);
    
                }
            }catch(e){
                alert(e.toString());
                setdorebirthStatus(0);
            }
    
          }else{
            alert('Please Connect Wallet First');
          }
    }

    async function doMint(){
        if(address.length > 0){
          try{
              setMintMonsterStatus(1)
              const _price = (0.0005)*((10**9));
              const contractCallGasLimit = 600_000;
              let baseAssetId = '0xf8f8b6283d7fa5b672b530cbb84fcccb4ff8dc40f8176ef4544ddb1f1952ad07';
              let result = await myContract.functions.mint_monster().callParams({
                forward: [_price, baseAssetId],
                gasLimit: contractCallGasLimit,
              }).call();
              let res = await result.waitForResult();
              if(res.transactionResult.status === 'success'){
                // setMintMonsterStatus(0);
                resetInfo();
              }else{
                // setMintStatus(3);
                alert('Transaction Failed');
  
              }
              setMintMonsterStatus(0);
          }catch(e){
            //   console.log(e);
            //   setMintStatus(3);
            alert(e.toString());
            setMintMonsterStatus(0);
          }
  
        }else{
          alert('Please Connect Wallet First');
        }
  
      }

    function checkStatus(_claimtime, _todtime, _genetime){
        const nowTime = +new Date();
        if(_claimtime -nowTime > 0){
            // setTimeout(() => {
                setClaimStatus(0)  
            // }, 0);
        }else{
            // setTimeout(() => {
                setClaimStatus(1)  
            // }, 0);
        }
        if(_todtime - nowTime > 0){
            // setTimeout(() => {
                setRebirthStatus(0)  
            // }, 0);
        }else{
            // setTimeout(() => {
                setRebirthStatus(1)  
            // }, 0);
        }
        if(_genetime - nowTime > 0){
            // setTimeout(() => {
                setGeneStatus(0)  
            // }, 0);
        }else{
            // setTimeout(() => {
                setGeneStatus(1)  
            // }, 0);
        }

    }

    function displayFeed(){
        setalertfeetStatus(1);
    }
    function displayAccelerate(){
        setalertaccelerateStatus(1);
    }
    function displayGene(){
        setalertprofileStatus(1);
    }

    useEffect ( ()=>{
        // console.log(info);  
        if(address.length > 0){
            if(info.length > 0){
                // if(info[0] == 'None'){
                if(!info[0]){
                    setMintButtonStatus(1);
                }else{
                    setMintButtonStatus(0);
                }
                // if(info[0] != 'None'){   
                if(info[0]){
                    // console.log(info);
                    dealIndex(cardIndex); 
                    // const expire = 1703246400000;
                    let claimtime = 1703246400000;
                    let epochtime = ((TAI64.fromHexString((info[4].epoch_time).toString(16)).toUnix()) + 60*60*24*15)*1000;
                    let todtime = 1703246400000;
                    let genetime = 1703246400000;
                    if(info[0]){
                        todtime=(TAI64.fromHexString((info[0].expiry).toString(16)).toUnix())*1000;
                        claimtime=((TAI64.fromHexString((info[0].cardtime).toString(16)).toUnix()) + 60*60*24)*1000;
                        genetime=((TAI64.fromHexString((info[0].genetime).toString(16)).toUnix()) + 60*60*24)*1000;
                    }
                    // console.log(todtime);
                    checkStatus(claimtime, todtime, genetime);
                    // console.log(info[4].epoch_time.toString());
                    // console.log(TAI64.fromHexString((info[4].epoch_time).toString(16)).toUnix());
                    setEpoch(info[4].epoch.toString());
                    timer.current = setInterval(()=>{
                        countDown(claimtime, 1);
                        countDown(epochtime, 2);
                        countDown(todtime, 3);
                        countDown(genetime, 4);
                    }, 1000);
                }else{
                    dealIndex(cardIndex); 
                    if(info[4]){
                        if(info[4].epoch.toString() > 0){
                            let epochtime = ((TAI64.fromHexString((info[4].epoch_time).toString(16)).toUnix()) + 60*60*24*15)*1000;
                            setEpoch(info[4].epoch.toString());
                            timer.current = setInterval(()=>{
                                countDown(epochtime, 2);
                            }, 1000);
                        }
                    }
                    
                }
            }
        }

        return()=>{
            // console.log('leave monster page------------');
            clearInterval(timer.current);
          }
        
      }, [address, info]);

    return (
        <div className='monster_page'>
            <div className='monster_contain'>
                <Title address={address} setAddress={setAddress} setMyContract={setMyContract} setMyWallet={setMyWallet} myname={myname} setMyName={setMyName}/>
                <div className='monster_content_top'>
                    <div className='monster_content_top_left'><li>Epoch{epoch} {epochName}</li></div>
                    <div className='monster_content_top_right'>
                        {claimStatus == 0 ? <li className='monster_content_top_right_label'>Claim In {claimName}</li>:(doclaimcardStatus == 0 ? <li className='monster_content_top_right_button' onClick={claimCard}>Claim Constellation</li>: <li className='monster_content_top_right_button' ><img src={loading}/></li>)}
                        </div>
                </div>
                <div className='monster_content'>
                    <div className='monster_content_left'>
                        <img src={monster_img}/>
                        <div className='monster_tod'>
                            {/* <li>TOD: {todName}</li> */}
                            {mintButtonStatus === 1 ? (mintMonsterStatus === 0 ? <div className='monster_tod_button_contain'><li className='rebirth_price'>Price: 0.0005 ETH</li><li className='monster_mint_button' onClick={doMint}>Mint Monster</li></div> : <div className='monster_tod_button_contain'><li className='rebirth_price'>Price: 0.0005 ETH</li><li className='monster_mint_button'><img src={loading}/></li></div>):(rebirthStatus === 0 ? (<li className='monster_tod_label'>TOD {todName}</li>):(dorebirthStatus == 0 ? <div className='monster_tod_button_contain'><li className='rebirth_price'>Price: 0.0003 ETH</li><li className='monster_tod_button' onClick={reBirth}>Rebirth</li></div>: <div className='monster_tod_button_contain'><li className='rebirth_price'>Price: 0.0003 ETH</li><li className='monster_tod_button' ><img src={loading}/></li></div>))}
                            {/* {} */}
                        </div>
                    </div>
                    <div className='monster_content_right'>
                        <div className='monster_accelerate' onClick={displayAccelerate}>
                            <img className='monster_right_img' src={accelerate_img} />
                            <li>Accelerate</li>
                        </div>
                        
                        <div className='monster_accelerate' onClick={displayFeed}>
                            <img className='monster_right_img' src={fruit_img}/>
                            <li>Feed</li>
                        </div>

                        <div className='monster_accelerate' onClick={displayGene}>
                            <img className='monster_right_img' src={profile_img}/>
                            <li>Profile</li>
                        </div>
                        
                    </div>
                </div>
                <div className='monster_card'>
                    <div className='monster_card_left_right' onClick={goLeft}>
                        <img src={left_img} />
                    </div>
                    <div className='monster_card_contrain'>
                        {cardNum1 === 0 ? (<div className='card_title_none'><li></li></div>):(<div className='card_title'><li>x{cardNum1}</li></div>)}
                        <div className={ cardNum1 === 0 ? 'monster_card_content1' : 'monster_card_content2'} >
                        {/* <div className='monster_card_content2'> */}
                            <img src={cardImg1}/>
                            {/* {cardNum1 === 0 ? (<li className='monster_card_name1'>{cardName1}</li>) : (<li className='monster_card_name2'>{cardName1}</li>)} */}
                            <li className='monster_card_name2'>{cardName1}</li>
                        </div>
                    </div>
                    <div className='monster_card_contrain'>
                        {cardNum2 === 0 ? (<div className='card_title_none'><li></li></div>):(<div className='card_title'><li>x{cardNum2}</li></div>)}
                        <div className={ cardNum2 === 0 ? 'monster_card_content1' : 'monster_card_content2'} >
                            <img src={cardImg2}/>
                            <li className='monster_card_name2'>{cardName2}</li>
                        </div>
                    </div>
                    <div className='monster_card_contrain'>
                        {cardNum3 === 0 ? (<div className='card_title_none'><li></li></div>):(<div className='card_title'><li>x{cardNum3}</li></div>)}
                        <div className={ cardNum3 === 0 ? 'monster_card_content1' : 'monster_card_content2'} >
                            <img src={cardImg3}/>
                            <li className='monster_card_name2'>{cardName3}</li>
                        </div>
                    </div>
                    <div className='monster_card_left_right' onClick={goRight}>
                        <img src={right_img} />
                    </div>
                    
                </div>
                <div className='monster_combine'>
                    <li className='monster_combine_price'>Price: 500 CON</li>
                   {docombineStatus === 0 ? <li className='monster_combine_button' onClick={doCombine}>Combine</li> : <li className='monster_combine_button'><img src={loading}/></li>}
                </div>

            </div>
            {alertfeetStatus === 0 ? '' : <AlertsFeed setalertfeetStatus={setalertfeetStatus} info={info} address={address} myContract={myContract} resetInfo={resetInfo} />}
            {alertaccelerateStatus === 0 ? '' : <AlertsAccelerate setalertaccelerateStatus={setalertaccelerateStatus} info={info} address={address} myContract={myContract} resetInfo={resetInfo} />}
            {alertprofileStatus === 0 ? '' : <AlertsProfile setalertprofileStatus={setalertprofileStatus} geneStatus={geneStatus} geneName={geneName} info={info} address={address} myContract={myContract} resetInfo={resetInfo} tokenBalance={tokenBalance}/>}
            {/* {alertConstellaStatus === 0 ? '' : <AlertsConstellation setalertConstellaStatus={setalertConstellaStatus}/>} */}
        </div>
        
        
    )

}

export default Monster