import React, { useEffect, useState} from 'react'
import close_img from '../icons/close.png'
import gene_img from '../icons/gene.png'
import point_img from '../icons/point.png'
import token_img from '../icons/token.png'
import sub_img from '../icons/sub.png'
import add_img from '../icons/add.png'
import loading from '../icons/loading.gif'
import '../styles/alertsprofile.css'

const AlertsProfile = ({setalertprofileStatus, geneStatus, geneName, info, address, myContract, resetInfo, tokenBalance}) =>{

  const [buyNum, setBuyNum] = useState(100);
  const [buyStatus, setBuyStatus] = useState(0);
  const [mygene, setMygene] = useState(0);
  const [mybonus, setMybonus] = useState(0);
  const [mypoint, setMypoint] = useState(0);
  const [battlepool, setBattlepool] = useState(0);
  const [dorenewGene, setDorenewGene] = useState(0);
  const [doclaimStatus, setdoclaimStatus] = useState(0);

  function closePage(){
    setalertprofileStatus(0);
  }

  function doPlus(){
    
    if(buyNum<10000){
      setBuyNum(buyNum + 100);
    }
  }

  function doSub(){
    if(buyNum>100){
      setBuyNum(buyNum - 100);
    }
  }
  function dealInit(){
    if(info){
      if(info[0]){
        // console.log(info[0].gene.toString());
        setMygene(info[0].gene.toString());
        setMybonus(info[0].bonus.toString());
      }
      if(info[4] && info[4].mypoint){
        setMypoint(info[4].mypoint.toString());
      }
      if(info[4] && info[4].my_battle_pool){
        setBattlepool(info[4].my_battle_pool.toString());
      }

    }
  }

  async function doRenewGene(){
    if(address.length > 0){
      if(info.length>0){   
        if(info[0]){
          
            try{
              setDorenewGene(1);
              let _price = ((0.0003).toFixed(4))*((10**9));
              const contractCallGasLimit = 500_000;
              // console.log(_price);
              // let amount = buyNum*((10**9));
              let baseAssetId = '0xf8f8b6283d7fa5b672b530cbb84fcccb4ff8dc40f8176ef4544ddb1f1952ad07';
              let result = await myContract.functions.regenerate_gene().callParams({
                forward: [_price, baseAssetId],
                gasLimit: contractCallGasLimit,
              }).call();
              let res = await result.waitForResult();
              if(res.transactionResult.status === 'success'){
                // getTokenBalance();
                resetInfo();
              }else{
                alert('Transaction Failed');
              }
              setDorenewGene(0);
            }catch(e){
                alert(e.toString());
                setDorenewGene(0);
            }
            
          
        }else{
          alert('Please Mint Monster First!');
        }
      }else{
        alert('Please Mint Monster First!');
      }
    }else{
      alert('Please Connect Wallet First!');
    }
  }

  async function doBuyCoin(){
    if(address.length > 0){
      if(info.length>0){   
        if(info[0]){
          
            try{
              setBuyStatus(1);
              let _price = ((0.000001 * buyNum).toFixed(4))*((10**9));
              const contractCallGasLimit = 500_000;
              // console.log(_price);
              let amount = buyNum*((10**9));
              let baseAssetId = '0xf8f8b6283d7fa5b672b530cbb84fcccb4ff8dc40f8176ef4544ddb1f1952ad07';
              let result = await myContract.functions.buy_coin(amount).callParams({
                forward: [_price, baseAssetId],
                gasLimit: contractCallGasLimit,
              }).call();
              // }).getTransactionCost();
              // console.log(result.gasUsed.toString());
              let res = await result.waitForResult();
              if(res.transactionResult.status === 'success'){
                resetInfo();
              }else{
                alert('Transaction Failed');
              }
              setBuyStatus(0);
            }catch(e){
                // let str = e.toString();
                // console.log(str);
                // console.log(str.split("at")[0]);
                alert(e.toString());
                setBuyStatus(0);
            }
            
          
        }else{
          alert('Please Mint Monster First!');
        }
      }else{
        alert('Please Mint Monster First!');
      }
    }else{
      alert('Please Connect Wallet First!');
    }
  }
  async function doClaim(){
    if(address.length > 0){
      if(info){   
        if(info[4] && info[4].my_battle_pool){
          if(info[4].my_battle_pool.toString() >0){          
            try{
              setdoclaimStatus(1);
              const contractCallGasLimit = 400_000;
              let result = await myContract.functions.claim_battle_pool(battlepool).callParams({
                gasLimit: contractCallGasLimit,
              }).call();
              let res = await result.waitForResult();
              if(res.transactionResult.status === 'success'){
                resetInfo();
              }else{
                alert('Transaction Failed');
              }
              setdoclaimStatus(0);
            }catch(e){
                alert(e.toString());
                setdoclaimStatus(0);
            }

          }
        }
      }else{
        alert('Please Mint Monster First!');
      }
    }else{
      alert('Please Connect Wallet First!');
    }

  }

  useEffect ( ()=>{
    if(address.length>0){
      dealInit();
      // console.log(info)
    }
  },[info, address]);
    
    return(
      <div className='alert_con'>
        <div className='alert_feed_child'>  
          <div className='alert_feed_title'><img src={close_img} onClick={closePage}/></div>   
          <div className='alert_profile_title'>
            <li>Profile</li></div> 
          <div className='alert_profile_contain'>
            <div className='alert_gene_img'>
              <img src={gene_img}/>
            </div>
            <div className='alert_profile_gene'>
              <div className='alert_gene_name'>
                <li className='alert_gene_name_left'>Gene:</li>
                <li className='alert_gene_name_right'>{mygene}</li>
              </div>
              <div className='alert_gene_bonus'>
                <li className='alert_gene_name_left'>Weight:</li>
                <li className='alert_gene_name_right'>{mybonus}</li>
              </div>
            </div>
            <div className='alert_gene_button'>
              {geneStatus === 0 ? (<li className='alert_gene_label'>{geneName}</li>) : (dorenewGene === 0 ? <div className='alert_gene_button_contain'><li className='alert_gene_price'>Gene Price: 0.0003 ETH</li><li className='alert_gene_button_do' onClick={doRenewGene}>Generate New Gene</li></div>: <div className='alert_gene_button_contain'><li className='alert_gene_price'>Gene Price: 0.0003 ETH</li><li className='alert_gene_button_do'><img src={loading}/></li></div>)}
            </div>
          </div>
          <div className='alert_profile_contain'>
            <div className='alert_gene_img'>
              <img src={point_img}/>
            </div>
            <div className='alert_profile_point'>
              <div className='alert_gene_name'>
                <li className='alert_point_name_left'>My Total Point:</li>
                <li className='alert_point_name_right'>{mypoint}</li>
              </div>
              <div className='alert_gene_bonus'>
                <li className='alert_point_name_left'>CON BattlePool:</li>
                <li className='alert_point_name_right'>{(battlepool/(10**9)).toFixed(0)}</li>
              </div>
            </div>
            <div className='alert_profile_point_right'>
              <div className='alert_profile_point_right_no'></div>
              <div className='alert_profile_point_right_button'>{doclaimStatus === 0 ? <li onClick={doClaim}>Claim</li> : <li><img src={loading}/></li>}</div>
            </div>
            
          </div>
          <div className='alert_profile_contain'>
              <div className='alert_gene_img'>
                <img src={token_img}/>
              </div>
              <div className='alert_profile_gene'>
                <div className='alert_gene_name'>
                  <li className='alert_token_left'>CON Balance:</li>
                  <li className='alert_token_right'>{tokenBalance}</li>
                </div>
                <div className='alert_gene_bonus'>
                  <li className='alert_token_left'>Total Buy Price:</li>
                  <li className='alert_token_right'>{(0.000001*buyNum).toFixed(4)} ETH</li>
                </div>
              </div>
              <div className='alert_buy_token'>
                  <div className='alert_token_buy_num'>
                    <img src={sub_img} onClick={()=>doSub(1)}/>
                    <li>{buyNum}</li>
                    <img src={add_img} onClick={()=>doPlus(1)}/>
                  </div>
                  <div className='alert_token_buy_do'>
                    {buyStatus === 0 ? <li onClick={()=>doBuyCoin()}>Buy</li>: <li><img src={loading}/></li>}
                  </div>
                
              </div>
            
          </div>
          {/* <div className='alert_gene_button'>
            <li>Generate New Gene</li>
          </div> */}
          {/* <div className='alert_gene_button'>
            <li onClick={doBuyCoin}>Buy Coin</li>
          </div> */}
        </div>
      </div>
    )
  }

  export default AlertsProfile