import React, { useEffect, useState, useRef} from 'react'
import '../styles/alertsrecord.css'
import close_img from '../icons/close.png'
import loading from '../icons/loading.gif'
import aries_img2 from '../icons/constellation/aries2.png'
import taurus_img2 from '../icons/constellation/taurus2.png'
import gemini_img2 from '../icons/constellation/gemini2.png'
import cancer_img2 from '../icons/constellation/cancer2.png'
import leo_img2 from '../icons/constellation/leo2.png'
import virgo_img2 from '../icons/constellation/virgo2.png'
import libra_img2 from '../icons/constellation/libra2.png'
import scorpio_img2 from '../icons/constellation/scorpio2.png'
import sagittarius_img2 from '../icons/constellation/sagittarius2.png'
import capricornus_img2 from '../icons/constellation/capricornus2.png'
import aquarius_img2 from '../icons/constellation/aquarius2.png'
import pisces_img2 from '../icons/constellation/pisces2.png'
import axios from 'axios'


const AlertsRecord = ({setalertrecordStatus, address}) =>{
  const recordRef = useRef(); //创建useRef
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [data, setData] = useState([]);


  function closePage(){
    setalertrecordStatus(0);
  }

  function getImg(data){
    if(data == 1){
      return aries_img2
    }else if(data == 2){
      return taurus_img2
    }else if(data == 3){
      return gemini_img2
    }else if(data == 4){
      return cancer_img2
    }else if(data == 5){
      return leo_img2
    }else if(data == 6){
      return virgo_img2
    }else if(data == 7){
      return libra_img2
    }else if(data == 8){
      return scorpio_img2
    }else if(data == 9){
      return sagittarius_img2
    }else if(data == 10){
      return capricornus_img2
    }else if(data == 11){
      return aquarius_img2
    }else if(data == 12){
      return pisces_img2
    }
  }

  function getName(data){
    if(data == 1){
      return 'Aries'
    }else if(data == 2){
      return 'Taurus'
    }else if(data == 3){
      return 'Gemini'
    }else if(data == 4){
      return 'Cancer'
    }else if(data == 5){
      return 'Leo'
    }else if(data == 6){
      return 'Virgo'
    }else if(data == 7){
      return 'Libra'
    }else if(data == 8){
      return 'Scorpio'
    }else if(data == 9){
      return 'Sa...us'
    }else if(data == 10){
      return 'Ca...us'
    }else if(data == 11){
      return 'Aq...us'
    }else if(data == 12){
      return 'Pisces'
    }
  }

  const ParsTime=(time)=>{
    let timestamp = time
    // 此处时间戳以毫秒为单位
    let date = new Date(parseInt(timestamp) * 1000);
    let Year = date.getFullYear();
    let Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    let Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
    let  GMT =  Year + '-' + Moth + '-' + Day;
    return GMT;
}

  const getDatas = async(_pages) =>{
      
    try{
      //   console.log(epoch)
        // const res = await axios.get('http://localhost:3001/getgamerecord', {
          const res = await axios.get('https://api.constellation.fun/getgamerecord', {
            params: {
              page_num: _pages,
              page_size: 10,
              address: address
            }
        })
        // console.log(res)
        if(res.status == 200){
          if(res.data.paging.total%res.data.paging.page_size == 0){
            if(parseInt(res.data.paging.total/res.data.paging.page_size) > 0){
              setTotalPage(parseInt(res.data.paging.total/res.data.paging.page_size))
            }
          }else{
              setTotalPage(parseInt(res.data.paging.total/res.data.paging.page_size) + 1)
          }
          let arr = []
          for(let i = 0; i < res.data.data.length; i++){
            // arr.push({id: (res.data.paging.page_num - 1)*10 + i, winer: res.data.data[i].winer, loser: res.data.data[i].loser, winer_back: res.data.data[i].winer_back, winer_win: res.data.data[i].winer_win, loser_get: res.data.data[i].loser_get, time: res.data.data[i].time.split('T')[0]})
            arr.push({id: (res.data.paging.page_num - 1)*10 + i, winer: res.data.data[i].winer, loser: res.data.data[i].loser, winer_back: res.data.data[i].winer_back, winer_win: res.data.data[i].winer_win, loser_get: res.data.data[i].loser_get, time: ParsTime(res.data.data[i].time)})
          }
          if(_pages == 1){
            setData(arr)
          }else{
            setData(data.concat(arr))
          }
          // console.log(_pages);
          // console.log(data);
          
         }
      }catch(e){
        console.log(e)
      }
    }
  
    const handleScroll = () =>{
      let res=recordRef.current.scrollHeight - recordRef.current.clientHeight- recordRef.current.scrollTop;
      // console.log(res)
      if (res>1) {
            //未到底
        } else {
            //已到底部
            // console.log('to bottom');
          //   console.log(page, totalPage);
            if(page < totalPage){
              if(address.length > 0){
                getDatas(page+1)
                setPage(page+1)
              }
            }
      }
    }

  useEffect ( ()=>{
      if(address.length > 0){
            getDatas(page);
          }
      
  }, [address]);
    
    return(
      <div className='alert_con'>
        <div className='alert_list_child'>  
          <div className='alert_list_title'><img src={close_img} onClick={closePage}/></div>    
          <div className='alert_record_contain' ref={recordRef} onScroll={handleScroll}>
            <div className='alert_record_title'>
              <li>Back</li>
              <li>Battle Date</li>
              <li>Win/Fail</li>
              <li>Get</li>
              <li>Lose</li>
            </div>
            {data.map(item => <div className='alert_record_content' key={item.id}>
              {
                item.winer === address ? (
                  <div className='alert_record_content_contain'>
                    <div className='alert_record_constella'>
                      <img src={getImg(item.winer_back)}/>
                      <li>{getName(item.winer_back)}</li>
                    </div>
                    <li className='record_item'>{item.time}</li>
                    <li className='battle_win'>Win</li>
                    <div className='alert_record_constella'>
                      <img src={getImg(item.winer_win)}/>
                      <li>{getName(item.winer_win)}</li>
                    </div>
                    <li className='record_item'>50 CON</li>
                  </div>
                ): (
                  <div className='alert_record_content_contain'>
                    <div className='alert_record_content'>
                      <li className='record_item'>50 CON</li>
                      <li className='record_item'>{item.time}</li>
                      <li className='battle_fail'>Fail</li>
                      <li className='record_item'>40 CON</li>
                      <div className='alert_record_constella'>
                        <img src={getImg(item.winer_win)}/>
                        <li>{getName(item.winer_win)}</li>
                      </div>
                    </div>
                  </div>
                )
              }
              
            </div>)}
            
          </div>
        </div>
      </div>
    )
  }

  export default AlertsRecord