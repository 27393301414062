import React, { useEffect, useState} from 'react'
import '../styles/alertsaccelerate.css'
import close_img from '../icons/close.png'
import choose_img from '../icons/choose.png'
import accelerate_img from '../icons/accelerate.png'
import loading from '../icons/loading.gif'

const AlertsAccelerate = ({setalertaccelerateStatus, info, address, myContract, resetInfo}) =>{

  const [chooseNum, setChooseNum] = useState(1);
  const [card1Num, setCard1Num] = useState(0);
  const [card2Num, setCard2Num] = useState(0);
  const [card3Num, setCard3Num] = useState(0);
  const [accelerateStatus, setAccelerateStatus] = useState(0);

  function dochoose(data){
    setChooseNum(data);
  }

  function closePage(){
    setalertaccelerateStatus(0);
  }

  async function doAccelerate(){
    if(address.length > 0){
      if(info.length>0){   
        if(info[3]){
          if(chooseNum === 1){
            if(info[3].eight_add > 0){
              try{
                setAccelerateStatus(1);
                const contractCallGasLimit = 400_000;
                let result = await myContract.functions.using_accelerator_card(chooseNum).callParams(
                  {
                    gasLimit: contractCallGasLimit,
                  }
                ).call();
                let res = await result.waitForResult();
                if(res.transactionResult.status === 'success'){
                    resetInfo();
                }else{
                  alert('Transaction Failed');
                }
                // setBuyStatus1(0);
              }catch(e){
                alert(e.toString());
              }
              setAccelerateStatus(0);
            }else{
              alert('Your Have 0 Accelerate Card1');
            }
          }else if(chooseNum === 2){
            if(info[3].sixteen_add > 0){
              try{
                setAccelerateStatus(1);
                let result = await myContract.functions.using_accelerator_card(chooseNum).callParams().call();
                let res = await result.waitForResult();
                if(res.transactionResult.status === 'success'){
                    resetInfo();
                }else{
                  alert('Transaction Failed');
                }
                // setBuyStatus1(0);
              }catch(e){
                alert(e.toString());
              }
              setAccelerateStatus(0);
            }else{
              alert('Your Have 0 Accelerate Card2');
            }
          }else if(chooseNum === 3){
            if(info[3].twentyfour_add > 0){
              try{
                setAccelerateStatus(1);
                let result = await myContract.functions.using_accelerator_card(chooseNum).callParams().call();
                let res = await result.waitForResult();
                if(res.transactionResult.status === 'success'){
                    resetInfo();
                }else{
                  alert('Transaction Failed');
                }
                // setBuyStatus1(0);
              }catch(e){
                alert(e.toString());
              }
              setAccelerateStatus(0);
            }else{
              alert('Your Have 0 Accelerate Card3');
            }
          }
        }else{
          alert('You Have 0 Accelerate Card');
        }
      }else{
        alert('Please Mint Monster First!');
      }
    }else{
      alert('Please Connect Wallet First!');
    }
  }

  useEffect ( ()=>{
    if(address.length > 0){
        if(info.length>0){   
          if(info[3]){
            setCard1Num(info[3].eight_add);
            setCard2Num(info[3].sixteen_add);
            setCard3Num(info[3].twentyfour_add);
          }        
        }
    }
    
  }, [info]);
    
    return(
      <div className='alert_con'>
        <div className='alert_accelerate_child'>  
          <div className='alert_accelerate_title'><img src={close_img} onClick={closePage}/></div>    
          <div className='alert_accelerate_contain'>
            <div className='alert_accelerate_content' >
              <div className='alert_accelerate_content_num'>
                <li>x{card1Num}</li>
              </div>
              <div className='alert_accelerate_content_info' onClick={()=>dochoose(1)}>
                <img src={accelerate_img}/>
                <li className='alert_accelerate_card1_time'>8 H</li>
                <li className='alert_accelerate_card1_name'>Accelerate Card1</li>
              </div>
              <div className='alert_accelerate_content_choose'>
               {chooseNum===1 ?<img src={choose_img}/>: <li></li>}
              </div>
            </div>
            <div className='alert_accelerate_content' >
              <div className='alert_accelerate_content_num'>
                <li>x{card2Num}</li>
              </div>
              <div className='alert_accelerate_content_info' onClick={()=>dochoose(2)}>
                <img src={accelerate_img}/>
                <li className='alert_accelerate_card1_time'>16 H</li>
                <li className='alert_accelerate_card1_name'>Accelerate Card2</li>
              </div>
              <div className='alert_accelerate_content_choose'>
               {chooseNum===2 ?<img src={choose_img}/>: <li></li>}
              </div>
            </div>
            <div className='alert_accelerate_content' >
              <div className='alert_accelerate_content_num'>
                <li>x{card3Num}</li>
              </div>
              <div className='alert_accelerate_content_info' onClick={()=>dochoose(3)}>
                <img src={accelerate_img}/>
                <li className='alert_accelerate_card1_time'>24 H</li>
                <li className='alert_accelerate_card1_name'>Accelerate Card3</li>
              </div>
              <div className='alert_accelerate_content_choose'>
               {chooseNum===3 ?<img src={choose_img}/>: <li></li>}
              </div>
            </div>
          </div> 
          <div className='alert_accelerate_button'>
            {accelerateStatus === 0 ?  <li onClick={doAccelerate}>Accelerate</li> : <li><img src={loading}/></li>}
          </div>
        </div>
      </div>
    )
  }

  export default AlertsAccelerate