import React, { useEffect, useState, useRef} from 'react'
import '../styles/alertslist.css'
import close_img from '../icons/close.png'
import loading from '../icons/loading.gif'
import axios from 'axios'
import aries_img2 from '../icons/constellation/aries2.png'
import taurus_img2 from '../icons/constellation/taurus2.png'
import gemini_img2 from '../icons/constellation/gemini2.png'
import cancer_img2 from '../icons/constellation/cancer2.png'
import leo_img2 from '../icons/constellation/leo2.png'
import virgo_img2 from '../icons/constellation/virgo2.png'
import libra_img2 from '../icons/constellation/libra2.png'
import scorpio_img2 from '../icons/constellation/scorpio2.png'
import sagittarius_img2 from '../icons/constellation/sagittarius2.png'
import capricornus_img2 from '../icons/constellation/capricornus2.png'
import aquarius_img2 from '../icons/constellation/aquarius2.png'
import pisces_img2 from '../icons/constellation/pisces2.png'


function GetConstellation({info, resetInfo, address, myContract}){
  const [ariesNum, setAriesNum] = useState(0);
  const [taurusNum, setTaurusNum] = useState(0);
  const [geminiNum, setGeminiNum] = useState(0);
  const [cancerNum, setCancerNum] = useState(0);
  const [leoNum, setLeoNum] = useState(0);
  const [virgoNum, setVirgoNum] = useState(0);
  const [libraNum, setLibraNum] = useState(0);
  const [scorpioNum, setScorpioNum] = useState(0);
  const [sagittariusNum, setSagittariusNum] = useState(0);
  const [capricornusNum, setCapricornusNum] = useState(0);
  const [aquariusNum, setAquariusNum] = useState(0);
  const [piscesNum, setPiscesNum] = useState(0);
  const [chooseType, setChooseType] = useState(0);
  const [listStatus, setListStatus] = useState(0);
  


  function getConstellationNum(){
    if(info[2]){
       setAriesNum(info[2].aries.toString());
       setTaurusNum(info[2].taurus.toString());
       setGeminiNum(info[2].gemini.toString());
       setCancerNum(info[2].cancer.toString());
       setLeoNum(info[2].leo.toString());
       setVirgoNum(info[2].virgo.toString());
       setLibraNum(info[2].libra.toString());
       setScorpioNum(info[2].scorpio.toString());
       setSagittariusNum(info[2].sagittarius.toString());
       setCapricornusNum(info[2].capricornus.toString());
       setAquariusNum(info[2].aquarius.toString());
       setPiscesNum(info[2].pisces.toString());
    }

  }

  async function doingList(data){
    try{
      setChooseType(data);
      setListStatus(1);
      const _price = (50)*((10**9));
      const contractCallGasLimit = 500_000;
      let assetId = '0x6b652ac9cf1f89fd76e4e8438611f7500fa6b8523bdd0609f0f045694bf0b321'; //token assetid
      let result = await myContract.functions.list_constellation(data).callParams(
        {
          forward: [_price, assetId],
          gasLimit: contractCallGasLimit,
        }
      ).call();
      let res = await result.waitForResult();
      // console.log(res);
      if(res.transactionResult.status === 'success'){
          resetInfo();
      }else{
        alert('Transaction Failed');
      }
      setListStatus(0);
    }catch(e){
        alert(e.toString());
        setListStatus(0);
    }

  }

  function doList(data){
    if(address.length > 0){
      if(info){   
        if(info[2]){
          if(data == 1){
            if(info[2].aries.toString() > 0){
              doingList(data);
            }else{
              alert('Your Have 0 Aries Card');
            }
          }else if(data == 2){
            if(info[2].taurus.toString() > 0){
              doingList(data);
            }else{
              alert('Your Have 0 Taurus Card');
            }
          }else if(data == 3){
            if(info[2].gemini.toString() > 0){
              doingList(data);
            }else{
              alert('Your Have 0 Gemini Card');
            }
          }else if(data == 4){
            if(info[2].cancer.toString() > 0){
              doingList(data);
            }else{
              alert('Your Have 0 Cancer Card');
            }
          }else if(data == 5){
            if(info[2].leo.toString() > 0){
              doingList(data);
            }else{
              alert('Your Have 0 Leo Card');
            }
          }else if(data == 6){
            if(info[2].virgo.toString() > 0){
              doingList(data);
            }else{
              alert('Your Have 0 Virgo Card');
            }
          }else if(data == 7){
            if(info[2].libra.toString() > 0){
              doingList(data);
            }else{
              alert('Your Have 0 Libra Card');
            }
          }else if(data == 8){
            if(info[2].scorpio.toString() > 0){
              doingList(data);
            }else{
              alert('Your Have 0 Scorpio Card');
            }
          }else if(data == 9){
            if(info[2].sagittarius.toString() > 0){
              doingList(data);
            }else{
              alert('Your Have 0 Sagittarius Card');
            }
          }else if(data == 10){
            if(info[2].capricornus.toString() > 0){
              doingList(data);
            }else{
              alert('Your Have 0 Capricornus Card');
            }
          }else if(data == 11){
            if(info[2].aquarius.toString() > 0){
              doingList(data);
            }else{
              alert('Your Have 0 Aquarius Card');
            }
          }else if(data == 12){
            if(info[2].pisces.toString() > 0){
              doingList(data);
            }else{
              alert('Your Have 0 Pisces Card');
            }
          }
          
            
        }else{
          alert('You Should Have 1 Card');
        }
      }else{
        alert('Please Mint Monster First!');
      }
    }else{
      alert('Please Connect Wallet First!');
    }
  }
  

  useEffect ( ()=>{
      if(info){
        if(info[2]){
          getConstellationNum();
        }
      } 
    }, [info]);

    
  return (
    <div className='alert_list_record' >
        <div className='alert_list_record_title'>
          <li className='alert_list_record_title_left'>Constellation Cards</li>
          <li className='alert_list_record_title_mid'>Amounts</li>
          <li className='alert_list_record_title_mid'>List Price</li>
          <li className='alert_list_record_title_right'></li>
        </div>
        <div className='alert_list_contain'>
          <div className='alert_list_contain_left'>
            <img src={aries_img2}/>
            <li>Aries</li>
          </div>
          <li className='alert_list_contain_mid'>{ariesNum}</li>
          <li className='alert_list_contain_mid'>50 CON</li>
          <div className='alert_list_contain_right'>
            {chooseType === 1 && listStatus === 1 ? <li><img src={loading}/></li> : <li onClick={()=>doList(1)}>List</li>}
          </div>
        </div>
        <div className='alert_list_contain'>
          <div className='alert_list_contain_left'>
            <img src={taurus_img2}/>
            <li>Taurus</li>
          </div>
          <li className='alert_list_contain_mid'>{taurusNum}</li>
          <li className='alert_list_contain_mid'>50 CON</li>
          <div className='alert_list_contain_right'>
          {chooseType === 2 && listStatus === 1 ? <li><img src={loading}/></li> : <li onClick={()=>doList(2)}>List</li>}
          </div>
        </div>
        <div className='alert_list_contain'>
          <div className='alert_list_contain_left'>
            <img src={gemini_img2}/>
            <li>Gemini</li>
          </div>
          <li className='alert_list_contain_mid'>{geminiNum}</li>
          <li className='alert_list_contain_mid'>50 CON</li>
          <div className='alert_list_contain_right'>
          {chooseType === 3 && listStatus === 1 ? <li><img src={loading}/></li> : <li onClick={()=>doList(3)}>List</li>}
          </div>
        </div>
        <div className='alert_list_contain'>
          <div className='alert_list_contain_left'>
            <img src={cancer_img2}/>
            <li>Cancer</li>
          </div>
          <li className='alert_list_contain_mid'>{cancerNum}</li>
          <li className='alert_list_contain_mid'>50 CON</li>
          <div className='alert_list_contain_right'>
          {chooseType === 4 && listStatus === 1 ? <li><img src={loading}/></li> : <li onClick={()=>doList(4)}>List</li>}
          </div>
        </div>
        <div className='alert_list_contain'>
          <div className='alert_list_contain_left'>
            <img src={leo_img2}/>
            <li>Leo</li>
          </div>
          <li className='alert_list_contain_mid'>{leoNum}</li>
          <li className='alert_list_contain_mid'>50 CON</li>
          <div className='alert_list_contain_right'>
          {chooseType === 5 && listStatus === 1 ? <li><img src={loading}/></li> : <li onClick={()=>doList(5)}>List</li>}
          </div>
        </div>
        <div className='alert_list_contain'>
          <div className='alert_list_contain_left'>
            <img src={virgo_img2}/>
            <li >Virgo</li>
          </div>
          <li className='alert_list_contain_mid'>{virgoNum}</li>
          <li className='alert_list_contain_mid'>50 CON</li>
          <div className='alert_list_contain_right'>
          {chooseType === 6 && listStatus === 1 ? <li><img src={loading}/></li> : <li onClick={()=>doList(6)}>List</li>}
          </div>
        </div>
        <div className='alert_list_contain'>
          <div className='alert_list_contain_left'>
            <img src={libra_img2}/>
            <li>Libra</li>
          </div>
          <li className='alert_list_contain_mid'>{libraNum}</li>
          <li className='alert_list_contain_mid'>50 CON</li>
          <div className='alert_list_contain_right'>
          {chooseType === 7 && listStatus === 1 ? <li><img src={loading}/></li> : <li onClick={()=>doList(7)}>List</li>}
          </div>
        </div>
        <div className='alert_list_contain'>
          <div className='alert_list_contain_left'>
            <img src={scorpio_img2}/>
            <li>Scorpio</li>
          </div>
          <li className='alert_list_contain_mid'>{scorpioNum}</li>
          <li className='alert_list_contain_mid'>50 CON</li>
          <div className='alert_list_contain_right'>
          {chooseType === 8 && listStatus === 1 ? <li><img src={loading}/></li> : <li onClick={()=>doList(8)}>List</li>}
          </div>
        </div>
        <div className='alert_list_contain'>
          <div className='alert_list_contain_left'>
            <img src={sagittarius_img2}/>
            <li>Sagittarius</li>
          </div>
          <li className='alert_list_contain_mid'>{sagittariusNum}</li>
          <li className='alert_list_contain_mid'>50 CON</li>
          <div className='alert_list_contain_right'>
          {chooseType === 9 && listStatus === 1 ? <li><img src={loading}/></li> : <li onClick={()=>doList(9)}>List</li>}
          </div>
        </div>
        <div className='alert_list_contain'>
          <div className='alert_list_contain_left'>
            <img src={capricornus_img2}/>
            <li>Capricornus</li>
          </div>
          <li className='alert_list_contain_mid'>{capricornusNum}</li>
          <li className='alert_list_contain_mid'>50 CON</li>
          <div className='alert_list_contain_right'>
          {chooseType === 10 && listStatus === 1 ? <li><img src={loading}/></li> : <li onClick={()=>doList(10)}>List</li>}
          </div>
        </div>
        <div className='alert_list_contain'>
          <div className='alert_list_contain_left'>
            <img src={aquarius_img2}/>
            <li>Aquarius</li>
          </div>
          <li className='alert_list_contain_mid'>{aquariusNum}</li>
          <li className='alert_list_contain_mid'>50 CON</li>
          <div className='alert_list_contain_right'>
          {chooseType === 11 && listStatus === 1 ? <li><img src={loading}/></li> : <li onClick={()=>doList(11)}>List</li>}
          </div>
        </div>
        <div className='alert_list_contain'>
          <div className='alert_list_contain_left'>
            <img src={pisces_img2}/>
            <li>Pisces</li>
          </div>
          <li className='alert_list_contain_mid'>{piscesNum}</li>
          <li className='alert_list_contain_mid'>50 CON</li>
          <div className='alert_list_contain_right'>
          {chooseType === 12 && listStatus === 1 ? <li><img src={loading}/></li> : <li onClick={()=>doList(12)}>List</li>}
          </div>
        </div>
      </div>
  )
}


function GetDelist({info, resetInfo, address, myContract}){
  const inviteRef = useRef(); //创建useRef
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [data, setData] = useState([]);
  const [chooseID, setChooseID] = useState(0);
  const [delistStatus, setDelistStatus] = useState(0);

  function getImg(data){
    if(data == 1){
      return aries_img2
    }else if(data == 2){
      return taurus_img2
    }else if(data == 3){
      return gemini_img2
    }else if(data == 4){
      return cancer_img2
    }else if(data == 5){
      return leo_img2
    }else if(data == 6){
      return virgo_img2
    }else if(data == 7){
      return libra_img2
    }else if(data == 8){
      return scorpio_img2
    }else if(data == 9){
      return sagittarius_img2
    }else if(data == 10){
      return capricornus_img2
    }else if(data == 11){
      return aquarius_img2
    }else if(data == 12){
      return pisces_img2
    }
  }

  function getName(data){
    if(data == 1){
      return 'Aries'
    }else if(data == 2){
      return 'Taurus'
    }else if(data == 3){
      return 'Gemini'
    }else if(data == 4){
      return 'Cancer'
    }else if(data == 5){
      return 'Leo'
    }else if(data == 6){
      return 'Virgo'
    }else if(data == 7){
      return 'Libra'
    }else if(data == 8){
      return 'Scorpio'
    }else if(data == 9){
      return 'Sagittarius'
    }else if(data == 10){
      return 'Capricornus'
    }else if(data == 11){
      return 'Aquarius'
    }else if(data == 12){
      return 'Pisces'
    }
  }

  function HandString(str){
    let length = str.length;
    let pre;
    if(str.length == 64){
        pre = str.substr(0,2) + '00' + str.substr(2,1);
        let end = str.substr(length - 4, 4);
        return pre+'...'+ end;
  
    }else if(str.length > 10){
        pre = str.substr(0,5);
        let end = str.substr(length - 4, 4);
        return pre+'...'+ end;
    }else{
      return str
    }
    // let pre = str.substr(0,5);
    
  }

  const ParsTime=(time)=>{
    let timestamp = time
    // 此处时间戳以毫秒为单位
    let date = new Date(parseInt(timestamp) * 1000);
    let Year = date.getFullYear();
    let Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    let Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
    let  GMT =  Year + '-' + Moth + '-' + Day;
    return GMT;
  }

  const getDatas = async(_pages, _address) =>{
    
    try{
      // const res = await axios.get('http://localhost:3001/getmylist', {
        const res = await axios.get('https://api.constellation.fun/getmylist', {
          params: {
            page_num: _pages,
            page_size: 100,
            address: _address,
            epoch: info[4].epoch.toString()
          }
      })
      // console.log(res)
      if(res.status == 200){
        if(res.data.paging.total%res.data.paging.page_size == 0){
          if(parseInt(res.data.paging.total/res.data.paging.page_size) > 0){
            setTotalPage(res.data.paging.total/res.data.paging.page_size)
          }
        }else{
            setTotalPage(res.data.paging.total/res.data.paging.page_size + 1)
        }
        let arr = []
        for(let i = 0; i < res.data.data.length; i++){
          arr.push({id: (res.data.paging.page_num - 1)*100 + i, constella: res.data.data[i].constella, listid: res.data.data[i].idb256, time: ParsTime(res.data.data[i].time)})
        }
        // setData(data.concat(arr))
        setData(arr);
       }
    }catch(e){
      console.log(e)
    }
  }

  const handleScroll = () =>{
    let res=inviteRef.current.scrollHeight - inviteRef.current.clientHeight- inviteRef.current.scrollTop;
    // console.log(res)
    if (res>1) {
          //未到底
      } else {
          //已到底部
          console.log('to bottom')
          if(page < totalPage){
            if(address.length > 0){
              getDatas(page+1, address)
              setPage(page+1)
            }
          }
    }
  }

  async function doDelist(data){
    if(address.length > 0){
      
      try{
          setChooseID(data);
          setDelistStatus(1);
          const contractCallGasLimit = 500_000;
          let result = await myContract.functions.delist_constellation(data).callParams({
            gasLimit: contractCallGasLimit,
          }).call();
          let res = await result.waitForResult();
          if(res.transactionResult.status === 'success'){
              // setdorebirthStatus(0);
              resetInfo();

              // setTimeout(() => {
              //   getDatas(1, address);
              // }, 5000);
              
          }else{
            alert('Transaction Failed');
              // setdorebirthStatus(0);
          }
          setDelistStatus(0);
      }catch(e){
          alert(e.toString());
          setDelistStatus(0);
      }

    }else{
      alert('Please Connect Wallet First');
    }

  }
  

  useEffect ( ()=>{
    if(address.length > 0){
      getDatas(page, address);
    }
      
    }, [address, info]);

    if(address.length>0){
      if(data.length> 0){
        return (
          <div className='alert_list_record' ref={inviteRef} onScroll={handleScroll}>
           <div className='alert_list_record_title'>
             <li className='alert_list_record_title_left'>Constellation Cards</li>
             <li className='alert_list_record_title_mid'>ID</li>
             <li className='alert_list_record_title_mid'>List Date</li>
             <li className='alert_list_record_title_right'></li>
           </div>
             
              {data.map(item => <div className='alert_list_contain' key={item.id}>
                    <div className='alert_list_contain_left'>
                      <img src={getImg(item.constella)}/>
                      <li>{getName(item.constella)}</li>
                    </div>
                    <li className='alert_list_contain_mid'>{HandString(item.listid)}</li>
                    {/* <li className='alert_list_contain_mid'>{item.listid}</li> */}
                    <li className='alert_list_contain_mid'>{item.time}</li>
                    <div className='alert_list_contain_right'>
                     {delistStatus === 1 && chooseID === item.listid ? <li><img src={loading}/></li> : <li onClick={()=>doDelist(item.listid)}>Delist</li>}
                    </div>
              </div>)}
            </div>
        )
      }else{
        return (
          <div className='alert_list_no_record' >
              No List Data Yet
            </div>
        )
      }

    }else{
      return (
        <div className='alert_list_no_record' >
            Please Connect Wallet First
          </div>
      )
    }
    
}

const AlertsList = ({setalertlistStatus, info, address, myContract, resetInfo}) =>{

  const [choose, setChoose] = useState(0);

  function closePage(){
    setalertlistStatus(0);
  }

  function choosing(data){
    setChoose(data);
  }
    
    return(
      <div className='alert_con'>
        <div className='alert_list_child'>  
          <div className='alert_list_title'><img src={close_img} onClick={closePage}/></div>    
          <div className='alert_list_choose'>
             <li className={choose=== 0 ? 'alert_list_click' : 'alert_list_noclick'} onClick={()=>choosing(0)}>List</li>
             <li className={choose=== 1 ? 'alert_delist_click' : 'alert_list_noclick'} onClick={()=>choosing(1)}>Delist</li>
             <li className='alert_list_none'></li>
          </div>
          {choose === 0 ? <GetConstellation info={info} address={address} myContract={myContract} resetInfo={resetInfo}/>: <GetDelist info={info} address={address} myContract={myContract} resetInfo={resetInfo}/>}
        </div>
      </div>
    )
  }

  export default AlertsList