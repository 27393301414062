import React, { useEffect, useState} from 'react'
import '../styles/alertsspin.css'
import close_img from '../icons/close.png'
import loading from '../icons/loading.gif'



const AlertsSpin = ({setalertspinStatus, resetInfo, setDoSpinStatus, rewardName}) =>{

  function closePage(){
    resetInfo();
    setDoSpinStatus(0);
    setalertspinStatus(0);
  }


  useEffect ( ()=>{
    
}, []);
    
    return(
      <div className='alert_con'>
        <div className='alert_list_child'>  
          <div className='alert_list_title'><img src={close_img} onClick={closePage}/></div>   
          <div className='alert_spin_contain'>
            <li className='alert_spin_contain_title'>Congratulations!</li>
            <li className='alert_spin_contain_content'>You Got {rewardName}</li>
          </div>
          
        </div>
      </div>
    )
  }

  export default AlertsSpin