import React, { useEffect, useState} from 'react'
import '../styles/alertsfeed.css'
import close_img from '../icons/close.png'
import choose_img from '../icons/choose.png'
import apple_img1 from '../icons/apple1.png'
import banana_img1 from '../icons/banana1.png'
import ananas_img1 from '../icons/ananas1.png'
import apple_img2 from '../icons/apple2.png'
import banana_img2 from '../icons/banana2.png'
import ananas_img2 from '../icons/ananas2.png'
import sub_img from '../icons/sub.png'
import add_img from '../icons/add.png'
import loading from '../icons/loading.gif'

const AlertsFeed = ({setalertfeetStatus, info, address, myContract, resetInfo}) =>{

    const [appleNum, setAppleNum] = useState(0);
    const [bananaNum, setBananaNum] = useState(0);
    const [ananasNum, setAnanasNum] = useState(0);
    const [chooseNum, setChooseNum] = useState(1);
    const [feedStatus, setFeedStatus] = useState(0);
    const [buyNum1, setBuyNum1] = useState(1);
    const [buyNum2, setBuyNum2] = useState(1);
    const [buyNum3, setBuyNum3] = useState(1);
    const [buyStatus1, setBuyStatus1] = useState(0);
    const [buyStatus2, setBuyStatus2] = useState(0);
    const [buyStatus3, setBuyStatus3] = useState(0);

    function closePage(){
      setalertfeetStatus(0);
    }
    function dochoose(data){
      setChooseNum(data);
    }

    function doPlus(data){
      if(data == 1){
        if(buyNum1<10){
          setBuyNum1(buyNum1 + 1);
        }
      }else if(data == 2){
        if(buyNum2<10){
          setBuyNum2(buyNum2 + 1);
        }
      }else if(data == 3){
        if(buyNum3<10){
          setBuyNum3(buyNum3 + 1);
        }
      }
    }

    function doSub(data){
      if(data == 1){
        if(buyNum1>1){
          setBuyNum1(buyNum1 - 1);
        }
      }else if(data == 2){
        if(buyNum2>1){
          setBuyNum2(buyNum2 - 1);
        }
      }else if(data == 3){
        if(buyNum3>1){
          setBuyNum3(buyNum3 - 1);
        }
      }
    }
    
    async function dofeed(){
      if(address.length > 0){
        if(info.length>0){   
          if(info[1]){
            if(chooseNum === 1){
              if(info[1].apple > 0){
                try{
                  setFeedStatus(1);
                  const contractCallGasLimit = 400_000;
                  let result = await myContract.functions.feed_fruit(chooseNum).callParams(
                    {
                      gasLimit: contractCallGasLimit,
                    }
                  ).call();
                  let res = await result.waitForResult();
                  if(res.transactionResult.status === 'success'){
                      resetInfo();
                  }else{
                    alert('Transaction Failed');
                  }
                  // setBuyStatus1(0);
                }catch(e){
                  alert(e.toString());
                }
                setFeedStatus(0);
              }else{
                alert('Your Have 0 Apple, Buy First!');
              }
            }else if(chooseNum === 2){
              if(info[1].banana > 0){
                try{
                  setFeedStatus(1);
                  const contractCallGasLimit = 400_000;
                  let result = await myContract.functions.feed_fruit(chooseNum).callParams(
                    {
                      gasLimit: contractCallGasLimit,
                    }
                  ).call();
                  let res = await result.waitForResult();
                  if(res.transactionResult.status === 'success'){
                      resetInfo();
                  }else{
                    alert('Transaction Failed');
                  }
                  // setBuyStatus1(0);
                }catch(e){
                  alert(e.toString());
                }
                setFeedStatus(0);
              }else{
                alert('Your Have 0 Banana, Buy First!');
              }
            }else if(chooseNum === 3){
              if(info[1].ananas > 0){
                try{
                  setFeedStatus(1);
                  const contractCallGasLimit = 400_000;
                  let result = await myContract.functions.feed_fruit(chooseNum).callParams(
                    {
                      gasLimit: contractCallGasLimit,
                    }
                  ).call();
                  let res = await result.waitForResult();
                  if(res.transactionResult.status === 'success'){
                      resetInfo();
                  }else{
                    alert('Transaction Failed');
                  }
                  // setBuyStatus1(0);
                }catch(e){
                  alert(e.toString());
                }
                setFeedStatus(0);
              }else{
                alert('Your Have 0 Pineapple, Buy First!');
              }
            }
            
            

          }else{
            alert('Please Buy Fruit First!');
          }
        }else{
          alert('Please Mint Monster First!');
        }
      }else{
        alert('Please Connect Wallet First!');
      }
    }

    async function doBuy(data){
      if(address.length > 0){
        if(info.length>0){   
          if(info[0]){
            
              try{
                let _price = (0.0001)*((10**9)) * buyNum1;
                if(data == 1){
                  setBuyStatus1(1);
                }else if(data == 2){
                  setBuyStatus2(1);
                  _price = _price*2
                }else if(data == 3){
                  setBuyStatus3(1);
                  _price = _price*4;
                }

                const contractCallGasLimit = 500_000;
              
                let baseAssetId = '0xf8f8b6283d7fa5b672b530cbb84fcccb4ff8dc40f8176ef4544ddb1f1952ad07';
                let result = await myContract.functions.buy_fruit(data, buyNum1).callParams({
                  forward: [_price, baseAssetId],
                  gasLimit: contractCallGasLimit,
                }).call();
                let res = await result.waitForResult();
                if(res.transactionResult.status === 'success'){
                    resetInfo();
                }else{
                  alert('Transaction Failed');
                }
                // setBuyStatus1(0);
              }catch(e){
                  // console.log(e);
                  alert(e.toString());
                  // setBuyStatus1(0);
              }
              if(data == 1){
                setBuyStatus1(0);
              }else if(data == 2){
                setBuyStatus2(0);
              }else if(data == 3){
                setBuyStatus3(0);
              }
            
          }else{
            alert('Please Mint Monster First!');
          }
        }else{
          alert('Please Mint Monster First!');
        }
      }else{
        alert('Please Connect Wallet First!');
      }
    }

    useEffect ( ()=>{
      if(address.length > 0){
          if(info.length>0){   
            if(info[1]){
              setAppleNum(info[1].apple);
              setBananaNum(info[1].banana);
              setAnanasNum(info[1].ananas);
            }        
          }
      }
      
    }, [info]);
    
    return(
      <div className='alert_con'>
        <div className='alert_feed_child'>  
          <div className='alert_feed_title'><img src={close_img} onClick={closePage}/></div>    
          <div className='alert_feed_contain'>
            <div className='alert_feed_content' >
              <div className='alert_feed_content_num'>
                {appleNum > 0 ?<li>x{appleNum}</li>: ''}
              </div>
              <div className={appleNum > 0 ? 'alert_feed_content_info': 'alert_feed_content_info_no'}>
                <div className={appleNum > 0 ? 'alert_feed_fruit' : 'alert_feed_fruit_no' } onClick={()=>dochoose(1)}>
                  <li>Apple</li>
                  <img src={appleNum > 0 ? apple_img2: apple_img1}/>
                  <li>TOD: 1 Day</li>
                  <li>0.0001 ETH/Piece</li>
                </div>
                <div className='alert_feed_buy'>
                  <div className='alert_feed_buy_num'>
                    <img src={sub_img} onClick={()=>doSub(1)}/>
                    <li>{buyNum1}</li>
                    <img src={add_img} onClick={()=>doPlus(1)}/>
                  </div>
                  <div className='alert_feed_buy_do'>
                    {buyStatus1 === 0 ? <li onClick={()=>doBuy(1)}>Buy</li>:<li><img src={loading}/></li>}
                  </div>
                </div>
              </div>
              <div className='alert_feed_content_choose'>
                {chooseNum===1 ?<img src={choose_img}/>: <li></li>}
              </div>
            </div>
            <div className='alert_feed_content' >
              <div className='alert_feed_content_num'>
                {bananaNum > 0 ?<li>x{bananaNum}</li>: ''}
              </div>
              <div className={bananaNum > 0 ? 'alert_feed_content_info': 'alert_feed_content_info_no'}>
                <div className={bananaNum > 0 ? 'alert_feed_fruit' : 'alert_feed_fruit_no'} onClick={()=>dochoose(2)}>
                  <li>Banana</li>
                  <img src={bananaNum > 0 ? banana_img2: banana_img1}/>
                  <li>TOD: 2 Days</li>
                  <li>0.0002 ETH/Piece</li>
                </div>
                <div className='alert_feed_buy'>
                  <div className='alert_feed_buy_num'>
                    <img src={sub_img} onClick={()=>doSub(2)}/>
                    <li>{buyNum2}</li>
                    <img src={add_img} onClick={()=>doPlus(2)}/>
                  </div>
                  <div className='alert_feed_buy_do'>
                  {buyStatus2 === 0 ? <li onClick={()=>doBuy(2)}>Buy</li>:<li><img src={loading}/></li>}
                  </div>
                </div>
              </div>
              <div className='alert_feed_content_choose'>
                {chooseNum===2 ?<img src={choose_img}/>: <li></li>}
              </div>
            </div>
            <div className='alert_feed_content' >
              <div className='alert_feed_content_num'>
                {ananasNum > 0 ?<li>x{ananasNum}</li>: ''}
              </div>
              <div className={ananasNum > 0 ? 'alert_feed_content_info': 'alert_feed_content_info_no'}>
                <div className={ananasNum > 0 ? 'alert_feed_fruit' : 'alert_feed_fruit_no'} onClick={()=>dochoose(3)}>
                  <li>Pineapple</li>
                  <img src={ananasNum > 0 ? ananas_img2: ananas_img1}/>
                  <li>TOD: 5 Days</li>
                  <li>0.0004 ETH/Piece</li>
                </div>
                <div className='alert_feed_buy'>
                  <div className='alert_feed_buy_num'>
                    <img src={sub_img} onClick={()=>doSub(3)}/>
                    <li>{buyNum3}</li>
                    <img src={add_img} onClick={()=>doPlus(3)}/>
                  </div>
                  <div className='alert_feed_buy_do'>
                  {buyStatus3 === 0 ? <li onClick={()=>doBuy(3)}>Buy</li>:<li><img src={loading}/></li>}
                  </div>
                </div>
              </div>
              <div className='alert_feed_content_choose'>
                {chooseNum===3 ?<img src={choose_img}/>: <li></li>}
              </div>
            </div>
          </div> 
          <div className='alert_feed_button'>
            {feedStatus === 0 ? <li onClick={dofeed}>Feed</li> : <li><img src={loading}/></li>}
          </div>
        </div>
      </div>
    )
  }

  export default AlertsFeed