import React, { useState, useRef, useEffect}  from 'react'
import '../styles/battle.css'
import list_img from '../icons/list.png'
import record_img from '../icons/record.png'
import aries_img2 from '../icons/constellation/aries2.png'
import taurus_img2 from '../icons/constellation/taurus2.png'
import gemini_img2 from '../icons/constellation/gemini2.png'
import cancer_img2 from '../icons/constellation/cancer2.png'
import leo_img2 from '../icons/constellation/leo2.png'
import virgo_img2 from '../icons/constellation/virgo2.png'
import libra_img2 from '../icons/constellation/libra2.png'
import scorpio_img2 from '../icons/constellation/scorpio2.png'
import sagittarius_img2 from '../icons/constellation/sagittarius2.png'
import capricornus_img2 from '../icons/constellation/capricornus2.png'
import aquarius_img2 from '../icons/constellation/aquarius2.png'
import pisces_img2 from '../icons/constellation/pisces2.png'
import Title from './title'
import AlertsList from './alertslist'
import AlertsRecord from './alertsrecord'
import AlertsBattle from './alertsbattle'
import axios from 'axios'

function GetBattlelist({info, resetInfo, address, myContract}){
    const battleRef = useRef(); //创建useRef
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [data, setData] = useState([]);
    const [bonusValue, setBonusValue] = useState(0);
    const [cardValue, setCardValue] = useState(0);
    const [alertlistStatus, setalertlistStatus] = useState(0);
    const [alertrecordStatus, setalertrecordStatus] = useState(0);
    const [alertbattleStatus, setalertbattleStatus] = useState(0);
    const [chooseGene, setchooseGene] = useState(0);
    const [chooseWeight, setchooseWeight] = useState(0);
    const [chooseConstella, setchooseConstella] = useState(0);
    const [chooseListid, setchooseListid] = useState(0);
    // const [types, setTypes] = useState(0);

    function handerBonusChange(e){
        // console.log(e.target.value)
        setBonusValue(e.target.value);
    }
    function handerCardChange(e){
        // console.log(e.target.value)
        setCardValue(e.target.value);
    }

    function disPlayList(){
        // console.log('display alert list');
        setalertlistStatus(1);
    }

    function disPlayRecord(){
        setalertrecordStatus(1);
    }

    function disPlayBattle(data1, data2, data3, data4){
      setchooseGene(data1);
      setchooseWeight(data2);
      setchooseConstella(data3);
      setchooseListid(data4);
      setalertbattleStatus(1);
  }
  
    function HandString(str){
      let length = str.length;
      let pre;
      if(str.length == 64){
          pre = str.substr(0,2) + '00' + str.substr(2,1);
    
      }else{
          pre = str.substr(0,5);
      }
      // let pre = str.substr(0,5);
      let end = str.substr(length - 4, 4);
      return pre+'...'+ end;
    }
  
    const getDatas = async(_pages, _epoch) =>{
      
      try{
        let _myconstella = cardValue;
        let _mybonus = bonusValue;
        if(_myconstella == 0 || _myconstella == 'All'){
            _myconstella = 'all'
        }
        if(_mybonus == 0 || _mybonus == 'All'){
           _mybonus = 'all'
        }
        // console.log(cardValue, bonusValue)
        // const res = await axios.get('http://localhost:3001/getbattlelistfilter', {
          const res = await axios.get('https://api.constellation.fun/getbattlelistfilter', {
            params: {
              page_num: _pages,
              page_size: 8,
              constella: _myconstella,
              bonus: _mybonus,
              epoch: _epoch,
            }
        })
        // console.log(res)
        if(res.status == 200){
          if(res.data.paging.total%res.data.paging.page_size == 0){
            if(parseInt(res.data.paging.total/res.data.paging.page_size) > 0){
              setTotalPage(parseInt(res.data.paging.total/res.data.paging.page_size))
            }
          }else{
              setTotalPage(parseInt(res.data.paging.total/res.data.paging.page_size) + 1)
          }
          let arr = []
          for(let i = 0; i < res.data.data.length; i++){
            arr.push({id: (res.data.paging.page_num - 1)*8 + i,listid: res.data.data[i].idb256, constella: res.data.data[i].constella, owner: res.data.data[i].owner, gene: res.data.data[i].ownergene, weight: res.data.data[i].bonus, epoch: res.data.data[i].epoch})
          }
          if(_pages == 1){
            setData(arr)

          }else{
            setData(data.concat(arr))

          }
          
         }
      }catch(e){
        console.log(e)
      }
    }
  
    const handleScroll = () =>{
      let res=battleRef.current.scrollHeight - battleRef.current.clientHeight- battleRef.current.scrollTop;
      // console.log(res)
      if (res>1) {
            //未到底
        } else {
            //已到底部
            // console.log('to bottom')
            if(page < totalPage){
              if(address.length > 0){
                getDatas(page+1, info[4].epoch.toString())
                setPage(page+1)
              }
            }
      }
    }

    function getImg(data){
      if(data == 1){
        return aries_img2
      }else if(data == 2){
        return taurus_img2
      }else if(data == 3){
        return gemini_img2
      }else if(data == 4){
        return cancer_img2
      }else if(data == 5){
        return leo_img2
      }else if(data == 6){
        return virgo_img2
      }else if(data == 7){
        return libra_img2
      }else if(data == 8){
        return scorpio_img2
      }else if(data == 9){
        return sagittarius_img2
      }else if(data == 10){
        return capricornus_img2
      }else if(data == 11){
        return aquarius_img2
      }else if(data == 12){
        return pisces_img2
      }
    }

    function getName(data){
      if(data == 1){
        return 'Aries'
      }else if(data == 2){
        return 'Taurus'
      }else if(data == 3){
        return 'Gemini'
      }else if(data == 4){
        return 'Cancer'
      }else if(data == 5){
        return 'Leo'
      }else if(data == 6){
        return 'Virgo'
      }else if(data == 7){
        return 'Libra'
      }else if(data == 8){
        return 'Scorpio'
      }else if(data == 9){
        return 'Sagittarius'
      }else if(data == 10){
        return 'Capricornus'
      }else if(data == 11){
        return 'Aquarius'
      }else if(data == 12){
        return 'Pisces'
      }
    }

    function doFilter(){
      // console.log(data)
      // setTypes(data);
      setData([]);
      setPage(1);
      if(address.length > 0){
        if(info){
          if(info[4]){
            getDatas(1, info[4].epoch.toString());
          }
        }
      }
      
    }
    
  
    useEffect ( ()=>{
      if(address.length > 0){
        if(info){
          if(info[4]){
            // console.log(page);
            setPage(1);
            getDatas(1, info[4].epoch.toString());
          }
        }
      }
      // console.log(myconstella, mybonus, page);
        
      }, [address, info]);
  
      if(address.length>0){
        if(data.length> 0){
          return (

            <div className='battle_contain' ref={battleRef} onScroll={handleScroll}>
              <div className='battle_title'>
                <div className='battle_title_filter'>
                        <select name = "dropdown" value={cardValue}
                                    onChange={(e)=>handerCardChange(e)}>
                            <option value = "0" >All</option>
                            <option value = "1" >Aries</option>
                            <option value = "2">Taurus</option>
                            <option value = "3">Gemini</option>
                            <option value = "4">Cancer</option>
                            <option value = "5">Leo</option>
                            <option value = "6">Virgo</option>
                            <option value = "7">Libra</option>
                            <option value = "8">Scorpio</option>
                            <option value = "9">Sagittarius</option>
                            <option value = "10">Capricornus</option>
                            <option value = "11">Aquarius</option>
                            <option value = "12">Pisces</option>
                        </select>
                </div>
                <div className='battle_title_filter'>
                        <select name = "dropdown" value={bonusValue}
                                    onChange={(e)=>handerBonusChange(e)}>
                            <option value = "0" >All</option>
                            <option value = "4" >Bonus 4</option>
                            <option value = "5">Bonus 5</option>
                            <option value = "6">Bonus 6</option>
                            <option value = "7">Bonus 7</option>
                            <option value = "8">Bonus 8</option>
                        </select>
                </div>
                <div className='do_filter'>
                    <li onClick={()=>doFilter()}>Filter</li>
                </div>
                <div className='battle_title_list'>
                    <img className='battle_title_img' src={list_img} onClick={disPlayList} />
                </div>
                <div className='battle_title_record'>
                    <img className='battle_title_img' src={record_img} onClick={disPlayRecord} />
                </div>

              </div>
              {data.map(item => <div className='battle_list' key={item.id}>
                <div className='list_left'>
                    <div className='list_left_card'>
                        <img src={getImg(item.constella)}/>
                        <li>{getName(item.constella)}</li>
                    </div>
                </div>
                <div className='list_mid'>
                    <li>Owner: {HandString(item.owner)}</li>
                    <li>Gene: {item.gene}</li>
                    <div className='list_mid_info'>
                        <li className='list_mid_bonus'>Weight: {item.weight}</li>
                        <li className='list_mid_epoch'>Epoch: {item.epoch}</li>
                    </div>
                </div>
                <div className='list_right'>
                    <div className='list_left_button'>
                        {(item.owner != address) && (<li onClick={()=>disPlayBattle(item.gene, item.weight, item.constella, item.listid)}>Battle</li>)}
                    </div>
                </div>
                </div>)}   
                {alertlistStatus === 0 ? '' : <AlertsList setalertlistStatus={setalertlistStatus} info={info} address={address} myContract={myContract} resetInfo={resetInfo} />}
                {alertrecordStatus === 0 ? '' : <AlertsRecord setalertrecordStatus={setalertrecordStatus} info={info} address={address} myContract={myContract} resetInfo={resetInfo} />}     
                {alertbattleStatus === 0 ? '': <AlertsBattle setalertbattleStatus={setalertbattleStatus} gene={chooseGene} weight={chooseWeight} constella={chooseConstella} listid={chooseListid} info={info} address={address} myContract={myContract} resetInfo={resetInfo} />}
            </div>
            
          )
        }else{
          return (
            <div className='battle_contain' ref={battleRef} onScroll={handleScroll}>
              <div className='battle_title'>
                <div className='battle_title_filter'>
                        <select name = "dropdown" value={cardValue}
                                    onChange={(e)=>handerCardChange(e)}>
                            <option value = "0" >All</option>
                            <option value = "1" >Aries</option>
                            <option value = "2">Taurus</option>
                            <option value = "3">Gemini</option>
                            <option value = "4">Cancer</option>
                            <option value = "5">Leo</option>
                            <option value = "6">Virgo</option>
                            <option value = "7">Libra</option>
                            <option value = "8">Scorpio</option>
                            <option value = "9">Sagittarius</option>
                            <option value = "10">Capricornus</option>
                            <option value = "11">Aquarius</option>
                            <option value = "12">Pisces</option>
                        </select>
                </div>
                <div className='battle_title_filter'>
                        <select name = "dropdown" value={bonusValue}
                                    onChange={(e)=>handerBonusChange(e)}>
                            <option value = "0" >All</option>
                            <option value = "4" >Bonus 4</option>
                            <option value = "5">Bonus 5</option>
                            <option value = "6">Bonus 6</option>
                            <option value = "7">Bonus 7</option>
                            <option value = "8">Bonus 8</option>
                        </select>
                </div>
                <div className='do_filter'>
                    <li onClick={()=>doFilter(cardValue + bonusValue)}>Filter</li>
                </div>
                <div className='battle_title_list'>
                    <img className='battle_title_img' src={list_img} onClick={disPlayList} />
                </div>
                <div className='battle_title_record'>
                    <img className='battle_title_img' src={record_img} onClick={disPlayRecord} />
                </div>

              </div>
                <div className='alert_list_no_records' >
                    No List Data Yet
                  </div>
                  {alertlistStatus === 0 ? '' : <AlertsList setalertlistStatus={setalertlistStatus} info={info} address={address} myContract={myContract} resetInfo={resetInfo} />}
                  {alertrecordStatus === 0 ? '' : <AlertsRecord setalertrecordStatus={setalertrecordStatus} info={info} address={address} myContract={myContract} resetInfo={resetInfo} />}     
            </div>
          )
        }
  
      }else{
        return (
          <div className='battle_contain' ref={battleRef} onScroll={handleScroll}>
              <div className='battle_title'>
                <div className='battle_title_filter'>
                        <select name = "dropdown" value={cardValue}
                                    onChange={(e)=>handerCardChange(e)}>
                            <option value = "0" >All</option>
                            <option value = "1" >Aries</option>
                            <option value = "2">Taurus</option>
                            <option value = "3">Gemini</option>
                            <option value = "4">Cancer</option>
                            <option value = "5">Leo</option>
                            <option value = "6">Virgo</option>
                            <option value = "7">Libra</option>
                            <option value = "8">Scorpio</option>
                            <option value = "9">Sagittarius</option>
                            <option value = "10">Capricornus</option>
                            <option value = "11">Aquarius</option>
                            <option value = "12">Pisces</option>
                        </select>
                </div>
                <div className='battle_title_filter'>
                        <select name = "dropdown" value={bonusValue}
                                    onChange={(e)=>handerBonusChange(e)}>
                            <option value = "0" >All</option>
                            <option value = "4" >Bonus 4</option>
                            <option value = "5">Bonus 5</option>
                            <option value = "6">Bonus 6</option>
                            <option value = "7">Bonus 7</option>
                            <option value = "8">Bonus 8</option>
                        </select>
                </div>
                <div className='do_filter'>
                    <li onClick={()=>doFilter(cardValue + bonusValue)}>Filter</li>
                </div>
                <div className='battle_title_list'>
                    <img className='battle_title_img' src={list_img} onClick={disPlayList} />
                </div>
                <div className='battle_title_record'>
                    <img className='battle_title_img' src={record_img} onClick={disPlayRecord} />
                </div>

              </div>
              <div className='alert_list_no_records' >
                  Please Connect Wallet First
                </div>
            </div>
        )
      }
      
  }

const Battle = ({info, resetInfo, address, setAddress, myContract, setMyContract, setMyWallet, myname, setMyName}) => {

    

    return (
        <div className='battle_page'>
            <div className='monster_contain'>
                <Title address={address} setAddress={setAddress} setMyContract={setMyContract} setMyWallet={setMyWallet} myname={myname} setMyName={setMyName}/>
            </div>
           <GetBattlelist info={info} address={address} myContract={myContract} resetInfo={resetInfo} />
        </div>

        
        
    )

}

export default Battle