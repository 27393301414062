import React, { useState, useEffect}  from 'react'
import '../styles/monster.css'
import logo_img from '../icons/logo.png'
import twitter from '../icons/twitter.png'
import { Fuel, Contract } from 'fuels';
import { FuelWalletConnector, FuelWalletDevelopmentConnector, FueletWalletConnector } from '@fuels/connectors';
import mynameAbi from "../json/name.json";
import mygameAbi from "../json/game.json";
import { useNavigate, useLocation } from 'react-router-dom';

const Title = ({ address, setAddress, setMyContract, setMyWallet, myname, setMyName }) => {

    // const [fueladdress, setFuelAddress] = useState([]);
    const isConnected = Boolean(address[0]);
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;
    const [choose, setChoose] = useState(pathname);

    // const namecontractId= '0xa09c043557bf12021893915c370457464fe6a308975a31156d2860c871726284';
    const gamecontractId = '0xe2817618615ebb756783c65939ebad3eed3dc8ffb7cb671062fa2fa26071179f';

    let fuel = new Fuel({
        connectors: [
          new FuelWalletDevelopmentConnector(),
          new FueletWalletConnector(),
          new FuelWalletConnector(),
        ],
      });
    // const sdk = new Fuel();


    const clickTurntable = () =>{
        setChoose('/turntable')
        navigate('/turntable')
    }
    const clickHome = () =>{
        setChoose('/')
        navigate('/')
    }
    const clickBattle = () =>{
        setChoose('/battle')
        navigate('/battle')
    }
    const clickBonus = () =>{
        setChoose('/bonus')
        navigate('/bonus')
    }
    
    function HandString(){
         
        // let str = fueladdress;
        let str = address;
        if(myname.length >0){
          str = myname;
          if(myname.length > 20){
            let pre = str.substr(0,8);
            let end = str.substr(str.length - 7, 7);
            return pre+'...'+ end;
          }else{
            return myname;
          }
          
        }else{
          let pre = str.substr(0,5);
          let end = str.substr(str.length - 4, 4);
          return pre+'...'+ end;
        }
    }

    async function connectAccount() {
      
        try{

          // await fuel.init();
          let res = await fuel.connect();
          // console.log(res);

          if(res){
              const currentAccount = await fuel.currentAccount();
              // setFuelAddress(currentAccount)
            //   setFuel(fuel);
              const wallet = await fuel.getWallet(currentAccount);
              setMyWallet(wallet);
              setAddress(wallet.address.toB256());  
              
              // const nameContract = new Contract(namecontractId, mynameAbi, wallet);
              const gameContract = new Contract(gamecontractId, mygameAbi, wallet);
              setMyContract(gameContract);
              // let result = await nameContract.functions.get_name({
              //     "Address": {
              //         "bits": wallet.address.toB256()
              //     }
              //   }
              //   ).get();
              // // console.log(result.value);
              // if(result.value){
              //   setMyName(result.value);
              // }
            
            }else{
              console.log('not connected');
            }
          
        }catch(e){
           console.log(e)
          alert('launch wallet error, please install fuel wallet and conncet it')
        }
        
    }

    async function disConnect(){
        setAddress([]);
        await fuel.disconnect();
        // const connectionState = await fuel.disconnect();
        // console.log(connectionState);
        
      }

    return (
        <div className='monster_title'>
                    <div className='monster_logo'>
                        <img src={logo_img}/>
                        <li>Constellation Battle</li>
                        <li><a href='https://x.com/constellationba' target="_blank"><img className='twitter' src={twitter}/></a></li>
                    </div>
                    <div className='title_tab'>
                      <div className='title_item' onClick={clickHome}>
                      {choose == '/' ? 
                        <li className='choose_item'>Monster</li>:
                        <li className='no_choose_item'>Monster</li>}
                      </div>
                      <div className='title_item' onClick={clickTurntable}>
                      {choose == '/turntable' ?
                        <li className='choose_item'>Spin</li>:
                        <li className='no_choose_item'>Spin</li>}
                        </div>
                      <div className='title_item' onClick={clickBattle}>
                      {choose == '/battle' ?
                        <li className='choose_item'>Battle</li>:
                        <li className='no_choose_item'>Battle</li>}
                        </div>
                      <div className='title_item' onClick={clickBonus}>
                      {choose == '/bonus' ?
                        <li className='choose_item'>Bonus</li>:
                        <li className='no_choose_item'>Bonus</li>}
                        </div>
                    </div>
                    <div className='monster_connect'>
                        {isConnected ? <li onClick={disConnect}><HandString /></li> : <li onClick={connectAccount}>Connect Wallet</li>}
                        
                    </div>
        </div>
    )
}

export default Title