import React, { useState, useEffect, useRef}  from 'react'
import '../styles/bonus.css'
import happy_img from '../icons/happy.png'
import unhappy_img from '../icons/unhappy.png'
import record_img from '../icons/record.png'
import Title from './title'
import AlertsReward from './alertsreward'
import axios from 'axios'



const Bonus = ({address, setAddress, myContract, setMyContract, setMyWallet, myname, setMyName, info, resetInfo}) => {
    const bonusRef = useRef(); //创建useRef
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [data, setData] = useState([]);
    const [alertrewardStatus, setalertrewardStatus] = useState(0);
    const [shouldReward, setShouldReward] = useState(0);
    const [totalWeight, setTotalWeight] = useState(0);
    const [myWeight, setMyWeight] = useState(0);
    const [epoch, setEpoch] = useState(1);
    const [eligible, setEligible] = useState(false);

    function disPlayReward(){
        setalertrewardStatus(1);
    }

    function HandString(str){
        let length = str.length;
        let pre;
        if(str.length == 64){
            pre = str.substr(0,2) + '00' + str.substr(2,1);
      
        }else{
            pre = str.substr(0,6);
        }
        // let pre = str.substr(0,5);
        let end = str.substr(length - 5, 5);
        return pre+'...'+ end;
      }

    function getBonus(){
        if(address.length > 0){
            if(info){
                if(info[5]){
                    if(info[5].my_share_bonus){
                        setMyWeight(info[5].my_share_bonus.toString());
                        setEligible(true);
                    }
                    if(info[5].epoch_total_share){
                        setTotalWeight(info[5].epoch_total_share.toString());
                        // console.log(info[5].epoch_total_share.toString());
                    }
                    setShouldReward(info[5].epoch_should_bonus.toString());
                }
                if(info[4]){
                    setEpoch(info[4].epoch.toString());
                }
            }
        }
    }

    const getDatas = async(_pages, _epoch) =>{
      
        try{
        //   console.log(epoch)
          // const res = await axios.get('http://localhost:3001/getenterpool', {
            const res = await axios.get('https://api.constellation.fun/getenterpool', {
              params: {
                page_num: _pages,
                page_size: 10,
                epoch: _epoch
              }
          })
        //   console.log(res)
          if(res.status == 200){
            if(res.data.paging.total%res.data.paging.page_size == 0){
              if(parseInt(res.data.paging.total/res.data.paging.page_size) > 0){
                setTotalPage(parseInt(res.data.paging.total/res.data.paging.page_size))
              }
            }else{
                setTotalPage(parseInt(res.data.paging.total/res.data.paging.page_size) + 1)
            }
            let arr = []
            for(let i = 0; i < res.data.data.length; i++){
              arr.push({id: (res.data.paging.page_num - 1)*10 + i, user: res.data.data[i].owner, weight: res.data.data[i].weight})
            }
            if(_pages == 1){
              setData(arr)
            }else{
              setData(data.concat(arr))
            }
            // console.log(_pages);
            // console.log(data);
            
           }
        }catch(e){
          console.log(e)
        }
      }
    
      const handleScroll = () =>{
        let res=bonusRef.current.scrollHeight - bonusRef.current.clientHeight- bonusRef.current.scrollTop;
        // console.log(res)
        if (res>1) {
              //未到底
          } else {
              //已到底部
              // console.log('to bottom');
              if(page < totalPage){
                if(address.length > 0){
                  getDatas(page+1, info[4].epoch.toString())
                  setPage(page+1)
                }
              }
        }
      }

    useEffect ( ()=>{
        if(address.length > 0){
            getBonus();
            if(info){
                if(info[4]){
                    getDatas(page, info[4].epoch.toString());
                }

            }
            
        }
        
    }, [address, info]);

    return (
        <div className='bonus_page'>
            
            <Title  address={address} setAddress={setAddress} setMyContract={setMyContract} setMyWallet={setMyWallet} myname={myname} setMyName={setMyName}/>
            <div className='bonus_contain'>
              <div className='bonus_top'>
                  <div className='bonus_top_left'>
                      <li>Current: Epoch {epoch}</li>
                      {/* <li>End In: 0d:0h:0m:0s</li> */}
                  </div>
                  <div className='bonus_top_right'>
                      <img src={record_img} onClick={disPlayReward}/>
                  </div>
              </div>
              <div className='bonus_img'>
                  <li>{(shouldReward/(10**9)).toFixed(5)} ETH</li>
              </div>
              {eligible ? 
              <div className='bonus_mid_eligible'>
                      <li>You Are Eligible</li>
                      <img src={happy_img}/>
              </div> : <div className='bonus_mid_eligible'>
                      <li>You Not Eligible</li>
                      <img src={unhappy_img}/>
              </div>}
              <div className='bonus_mid'>
                      <li className='bonus_mid_left'>My Weight: {myWeight}</li>
                      <li className='bonus_mid_right'>Total Weight: {totalWeight}</li>
              </div>
              {data.length>0 ? 
              (<div className='bonus_list' ref={bonusRef} onScroll={handleScroll}>
                  <div className='bonus_list_title'>
                      <li>User</li>
                      <li>Weight</li>
                  </div>
                {data.map(item => <div className='bonus_list_detail' key={item.id}>
                  
                      <li>{HandString(item.user)}</li>
                      <li>{item.weight}</li>
                  </div>)}
                
              </div>): <div className='bonus_list'> <li className='bonus_list_nodata'>No Data Yet</li></div>}
              {alertrewardStatus === 0 ? '' : <AlertsReward setalertrewardStatus={setalertrewardStatus} info={info} address={address} myContract={myContract} resetInfo={resetInfo} />}
          </div>
        </div>        
        
    )

}

export default Bonus