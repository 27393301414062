import './App.css';
import BottomTabBar from './component/bottomtabbar';
import Monster from "./component/monster"
import Turntables from './component/turntable'
import Battle from './component/battle'
import Bonus from './component/bonus'
import {BrowserRouter, Link, Routes, Route} from "react-router-dom"
import Alerts from './component/alerts'
import { useState, useEffect } from "react";
// import AlertsMint from './component/alertsmint'

function App() {
  
  const [address, setAddress] = useState([]);
  // const [fuel, setFuel] = useState(null);
  const [myContract, setMyContract] = useState(null);
  const [myWallet, setMyWallet] = useState(null);
  const [myname, setMyName] = useState([]);
  // const [mintAlertStatus, setMintAlertStatus] = useState(0);
  const [info, setInfo] = useState([]);
  const [tokenBalance, setTokenBalance] = useState(0);

  async function getInfo(){
    const identityCoder = {
      "Address": {
          "bits": address
      }
     };
    let result = await myContract.functions.get_my_info(identityCoder).get();
    // console.log(result.value)
    if(result.value){
      // console.log(result.value);
      setInfo(result.value);
      // setMintAlertStatus(0)
    // }else{
      // setMintAlertStatus(1)
    }

    if(myWallet){
      let assetId = '0x6b652ac9cf1f89fd76e4e8438611f7500fa6b8523bdd0609f0f045694bf0b321'; //token assetid
      let balance = await myWallet.getBalance(assetId);
      setTokenBalance(balance.toString()/1000000000);
    }
    
  }

  async function resetInfo(){
    getInfo();
  }

useEffect ( ()=>{
    
    // console.log(address);
    if(address.length > 0){
        getInfo()
        // console.log(address);
    }

  }, [address]);

  return (
    <div className="App">
      <BrowserRouter>
          <Link to="/"></Link>
          <Link to="/turntable"></Link>
          <Link to="/battle"></Link>
          <Link to="/bonus"></Link>
          <Routes>
            <Route path="/" element={<Monster resetInfo={resetInfo} info={info} address={address} setAddress={setAddress} myContract={myContract} myWallet={myWallet} setMyContract={setMyContract} setMyWallet={setMyWallet} myname={myname} setMyName={setMyName} tokenBalance={tokenBalance} />}></Route>
            <Route path="/turntable" element={<Turntables resetInfo={resetInfo} info={info} address={address} setAddress={setAddress} myContract={myContract} setMyContract={setMyContract} setMyWallet={setMyWallet} myname={myname} setMyName={setMyName} />}></Route>
            <Route path="/battle" element={<Battle resetInfo={resetInfo} info={info} address={address} setAddress={setAddress} myContract={myContract} setMyContract={setMyContract} setMyWallet={setMyWallet} myname={myname} setMyName={setMyName} />}></Route>
            <Route path="/bonus" element={<Bonus resetInfo={resetInfo} info={info} address={address} setAddress={setAddress} myContract={myContract} setMyContract={setMyContract} setMyWallet={setMyWallet} myname={myname} setMyName={setMyName} />}></Route>
          </Routes>
          {/* <Alerts /> */}
          <BottomTabBar setAddress={setAddress} address={address} />
          {/* {mintAlertStatus == 1 ? <AlertsMint myContract={myContract} address={address} setMintAlertStatus={setMintAlertStatus}/>:''} */}
      </BrowserRouter>
      
    </div>
  );
}

export default App;
