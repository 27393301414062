import React, {useEffect, useState} from 'react'
import '../styles/bottomtabbar.css'
import { useNavigate, useLocation } from 'react-router-dom'
import monster_img1 from '../icons/monster1.png'
import monster_img2 from '../icons/monster2.png'
import battle_img1 from '../icons/battle1.png'
import battle_img2 from '../icons/battle2.png'
import turntable_img1 from '../icons/turntable1.png'
import turntable_img2 from '../icons/turntable2.png'
import bonus_img1 from '../icons/bonus1.png'
import bonus_img2 from '../icons/bonus2.png'
// import Alerts from './alerts'
// import FirstAlerts from './firstAlerts'



const BottomTabBar = ({address, setAddress}) => {
    // const [choose, setChoose] = useState('/');
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;
    const [choose, setChoose] = useState(pathname);
    // const { t } =useTranslation()
    // const [alerts, setAlert] = useState(true);

    const clickTurntable = () =>{
        setChoose('/turntable')
        navigate('/turntable')
    }
    const clickHome = () =>{
        setChoose('/')
        navigate('/')
    }
    const clickBattle = () =>{
        setChoose('/battle')
        navigate('/battle')
    }
    const clickBonus = () =>{
        setChoose('/bonus')
        navigate('/bonus')
    }
    
    // useEffect ( ()=>{
    //     if(pathname == '/turntable'){
    //         setChoose('/turntable');
    //     }else if(pathname == '/battle'){
    //         setChoose('/battle')
    //     }else if(pathname == '/bonus'){
    //         setChoose('/bonus')
    //     }else{
    //         setChoose('/')
    //     }
    //   }, []);
    
    return (
        <div className='tab-bar'>
            <div className='tab-group'>
                <div className='tab-group-item' onClick={clickHome}>
                    {choose == '/' ?
                    <div className='tab_li_group'>
                        <img src={monster_img2}/>
                        <li className='choose_tab'>Monster</li>
                    </div>:<div className='tab_li_group'>
                        <img src={monster_img1}/>
                        <li>Monster</li>
                    </div>}
                </div>
                <div className='tab-group-item' onClick={clickTurntable}>
                    {choose == '/turntable' ?
                    <div className='tab_li_group'>
                        <img src={turntable_img2}/>
                        <li className='choose_tab'>Spin</li>
                    </div>:<div className='tab_li_group'>
                        <img src={turntable_img1}/>
                        <li>Spin</li>
                    </div>}
                </div>
                <div className='tab-group-item' onClick={clickBattle}>
                    {choose == '/battle' ?
                    <div className='tab_li_group'>
                        <img src={battle_img2}/>
                        <li className='choose_tab'>Battle</li>
                    </div>:<div className='tab_li_group'>
                        <img src={battle_img1}/>
                        <li>Battle</li>
                    </div>}
                </div>
               <div className='tab-group-item' onClick={clickBonus}>
                    {choose == '/bonus' ?
                    <div className='tab_li_group'>
                        <img src={bonus_img2}/>
                        <li className='choose_tab'>Bonus</li>
                    </div>:<div className='tab_li_group'>
                        <img src={bonus_img1}/>
                        <li>Bonus</li>
                    </div>}
                </div>
            </div>  
        </div>
        
    )

}

export default BottomTabBar